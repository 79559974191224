import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { FormInputField, ShadowedButton } from '../../Common/common-index'
import {
  ProfileSettingPages,
  switchProfilePage,
} from '../../Containers/ProfileSettings/profileSettingsSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { authService } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& div[class*="fieldItem"]': { width: '100%' },
    '& div[class*="MuiFormControl-root"]': {
      width: '100%',
      '& p': {
        width: '100%',
        wordBreak: 'break-word',
        hyphens: 'auto',
        '&[class*="makeStyles-label"]': { fontFamily: 'Graphik-Regular' },
      },
      '& input': { fontSize: '14px', fontFamily: 'Graphik-Regular' },
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '270px',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'initial',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  fieldItem: {
    marginTop: theme.spacing(5),
    color: 'white',
  },
  button: {
    marginTop: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  errorStatus: {
    color: theme.palette.error.main,
    fontSize: '16px',
    marginTop: '30px',
    marginBottom: '-30px',
  },
}))

export const ChangePassword: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [errorResponse, setErrorResponse] = useState()

  const [oldPasswordFieldTouched, setOldPasswordFieldTouched] = useState<boolean>(false)
  const [newPasswordFieldTouched, setNewPasswordFieldTouched] = useState<boolean>(false)
  const [confirmNewPasswordFieldTouched, setConfirmNewPasswordFieldTouched] =
    useState<boolean>(false)

  const { mutate, error, isError, isSuccess } = useMutation((params: any) =>
    authService.changePassword2(params)
  )

  useEffect(() => {
    if (isSuccess) {
      dispatch(switchProfilePage(ProfileSettingPages.PasswordChangeSuccessPage))
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setErrorResponse((error as any)?.response?.data?.message)
    }
  }, [isError])

  const onSubmit = (oldPassword: string, newPassword: string) => {
    if (currentUser) {
      mutate({
        username: currentUser.username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
    }
  }

  const onSubmitForm = (e?: any) => {
    e.preventDefault()
    onSubmit(oldPassword, confirmNewPassword)
  }

  const isFormValid = () => {
    return (
      oldPassword &&
      newPassword &&
      confirmNewPassword &&
      oldPassword !== newPassword &&
      newPassword === confirmNewPassword
    )
  }

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <form className={classes.root}>
        <div className={classes.headerWrapper}>
          <Typography variant={'body2'} data-testid='title' style={{ color: 'white' }}>
            {t('authPages.changePasswordForm.description')}
          </Typography>
        </div>
        <Grid
          container
          className={classes.headerWrapper}
          direction='column'
          justifyContent='center'
        >
          <Grid item xs={12} className={classes.fieldItem}>
            <FormInputField
              id='profile-change-password-current-password'
              title={t('authPages.changePasswordForm.currentPassword')}
              placeholder={t('authPages.changePasswordForm.enterCurrentPasswordLabel')}
              value={oldPassword}
              isPasswordField={true}
              autoComplete={'newPassword'}
              inputProps={{
                maxLength: 2000,
              }}
              error={!oldPassword && oldPasswordFieldTouched}
              errorMessage={t('authPages.changePasswordForm.oldPasswordErrorMessage')}
              onChange={(e) => {
                setOldPassword(e)
                setOldPasswordFieldTouched(true)
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldItem}>
            <FormInputField
              id='profile-change-password-new-password'
              title={t('authPages.changePasswordForm.newPassword')}
              placeholder={t('authPages.changePasswordForm.enterNewPasswordLabel')}
              value={newPassword}
              isPasswordField={true}
              autoComplete={'newPassword'}
              inputProps={{
                maxLength: 2000,
              }}
              error={!newPassword && newPasswordFieldTouched}
              errorMessage={t('authPages.changePasswordForm.newPasswordErrorMessage')}
              onChange={(e) => {
                setNewPassword(e)
                setNewPasswordFieldTouched(true)
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldItem}>
            <FormInputField
              id='profile-change-password-confirm-password'
              title={t('authPages.changePasswordForm.confirmNewPassword')}
              placeholder={t('authPages.changePasswordForm.enterConfirmPasswordLabel')}
              value={confirmNewPassword}
              isPasswordField={true}
              autoComplete={'confirm-password'}
              inputProps={{
                maxLength: 2000,
              }}
              error={!confirmNewPassword && confirmNewPasswordFieldTouched}
              errorMessage={t('authPages.changePasswordForm.confirmPasswordErrorMessage')}
              onChange={(e) => {
                setConfirmNewPassword(e)
                setConfirmNewPasswordFieldTouched(true)
              }}
            />
          </Grid>
        </Grid>
        {errorResponse && (
          <Typography variant='subtitle2' className={classes.errorStatus}>
            {errorResponse}
          </Typography>
        )}
        <ShadowedButton
          id='profile-change-password-submit'
          text={t('authPages.changePasswordForm.saveButton')}
          buttonStyle={'default'}
          wrapperClasses={classes.button}
          disabled={!isFormValid()}
          onClick={onSubmitForm}
        ></ShadowedButton>
      </form>
    </Slide>
  )
}

export default ChangePassword
