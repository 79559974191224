import React, { useState, FC } from 'react'

export const Disclaimer: FC = () => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)

  const disclaimerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: '#fff',
    padding: '16px',
    fontSize: '12px',
    transition: 'transform 0.3s ease',
    transform: isDisclaimerOpen ? 'translateY(0)' : 'translateY(calc(100% - 70px))',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Graphik',
  }

  const disclaimerButtonStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    color: '#fff',
    padding: '8px 16px',
    cursor: 'pointer',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
    fontWeight: '800',
  }

  const pStyle: React.CSSProperties = {
    textAlign: 'center',
  }

  const aLinkStyle: React.CSSProperties = {
    color: '#9333ea',
  }

  return (
    <div style={disclaimerStyle}>
      <button style={disclaimerButtonStyle} onClick={() => setIsDisclaimerOpen(!isDisclaimerOpen)}>
        <span style={{ marginRight: '10px' }}>Disclaimer</span> {isDisclaimerOpen ? '▼' : '▲'}
      </button>
      <p style={pStyle}>
        The protection of your personal data is very important to Accenture and any personal data
        you provide through this tool may be processed by Accenture. Accenture is committed to
        keeping your personal data secure and processing it in accordance with applicable privacy
        laws and our internal policies.
      </p>
      <p style={pStyle}>
        Personal data Accenture processes may be transferred or be accessible internationally
        throughout Accenture’s global business and between its entities and affiliates, and third
        parties.
      </p>
      <p style={pStyle}>
        Before using this tool, Accenture invites you to carefully read its{' '}
        <a
          href='https://www.accenture.com/us-en/about/privacy-policy'
          target='_blank'
          style={aLinkStyle}
        >
          Privacy Statement
        </a>
        , which includes important information on why and how Accenture is processing your personal
        data.
      </p>
      <p style={pStyle}>
        Accenture is using local storage technology (similar to functional cookies) on this site.
        This technology temporarily caches some limited information on your system ensuring a swift
        and smooth user experience. Any cookies and similar technology are used in accordance with
        Accenture's{' '}
        <a
          href='https://www.accenture.com/us-en/support/company-cookies-similar-technology'
          target='_blank'
          style={aLinkStyle}
        >
          cookies policy
        </a>
      </p>
    </div>
  )
}
