import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {
  authService,
  FrontendTypes,
  localStorageService,
  ProviderResponse,
  // Provider,
} from '../../../Services/services-index'
import TextField from '@material-ui/core/TextField'
import { Button, Box, Checkbox, createStyles, makeStyles, Theme } from '@material-ui/core'

interface Props {
  onSubmit: Function
  loginFailMsg: string | undefined
  eventCodeFromQuery: string
  accessCodeFromQuery: string
  providers?: ProviderResponse
}

const useStyles = (providers?: any) =>
  makeStyles((theme: Theme) => {
    const providersButtons = {}
    providers.map((provider: any) => {
      providersButtons[`button-${provider.name}`] = {
        ...provider.button_attributes,
        ...provider.hover_attributes,
      }
    })
    return createStyles({
      oceanRaceCaption: {
        fontFamily: 'Graphik-Regular',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '17px',
      },
      header: {
        lineHeight: '32px',
        [theme.breakpoints.up('sm')]: {
          width: '100%',
        },
      },
      ...providersButtons,
      root: {
        '& .MuiInput-underline': {
          borderBottom: '1px solid white',
        },
        '& .MuiFormControl-root': {
          width: '100%',
        },
        '& .MuiTypography-root': {
          fontFamily: 'Graphik',
        },
        '& .MuiButton-root': {
          textTransform: 'none',
          backgroundColor: '#e0e0e0',
        },
        '& .MuiCheckbox-root': {
          color: '#e0e0e0',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'Graphik',
        backgroundColor: '#1E1E1E',
      },
      mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(10, 2),
        width: '450px',
        backgroundColor: '#000000',
      },
      titleContainer: {
        margin: theme.spacing(5, 0),
        width: '70%',
      },
      titleContainerChild: {
        fontWeight: 'bold',
        fontSize: 36,
        lineHeight: '22px',
        width: '100%',
        textAlign: 'justify',
      },
      buttonContainer: {
        margin: theme.spacing(0, 0, 3, 0),
        width: '70%',
        color: 'white',
      },
      providerButton: {
        margin: theme.spacing(2, 0, 2, 0),
        width: '100%',
        backgroundColor: 'white',
      },
      inputContainer: {
        marginTop: theme.spacing(5, 0),
        marginBottom: theme.spacing(3, 0),
        width: '70%',
      },
      checkboxContainer: {
        margin: theme.spacing(2, 0, 5, 0),
        width: '75%',
        alignItems: 'center',
        backgroundColor: '#000000',
      },
      headerTitleThird: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(7, 2),
        backgroundColor: '#1E1E1E',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '10px',
        width: '100%',
        textAlign: 'justify',
      },
      input: {
        '&:before': {
          borderBottom: '1px solid #000 !important', // ensures the line is always visible
        },
        '&:hover:before': {
          borderBottom: '1px solid #000 !important', // ensures the line is always visible on hover
        },
        '&:after': {
          borderBottom: '2px solid #000 !important', // ensures the line is always visible when focused
        },
        color: 'white',
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '36px',
        width: '100%',
        textAlign: 'justify',
        margin: theme.spacing(1, 0),
        boxSizing: 'border-box',
      },
      inputCredentials: {
        color: 'white',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '36px',
        width: '100%',
        textAlign: 'justify',
        margin: theme.spacing(1, 0),
        boxSizing: 'border-box',
        '&:-webkit-autofill': {
          transition: 'background-color 0s 600000s, color 0s 600000s !important',
        },
        '&:-webkit-autofill:focus': {
          transition: 'background-color 0s 600000s, color 0s 600000s !important',
        },
      },
      smallCaption: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '17px',
        width: '100%',
        textAlign: 'center',
        color: '#85adff',
      },
      checkboxContainerTypography: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '12px',
        alignItems: 'center',
      },
      checkboxContainerBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        textAlign: 'left',
        alignItems: 'center',
        height: '25px',
      },
      checkboxContainerSpan: {
        flex: 1,
      },
      checkboxContainerLink: {
        color: 'white',
      },
      checkboxClass: {
        color: 'white',
      },
      button: {
        height: '30px',
        width: '100%',
        textTransform: 'none',
        transition: 'all 0.4s ease-in-out',
        backgroundColor: 'white',
        color: 'white',
      },
      buttonSpan: {
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '12px',
        alignItems: 'center',
        color: 'black',
      },
      loginButtonSpan: {
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '12px',
        alignItems: 'center',
        color: 'white',
      },
      loginButton: {
        marginTop: theme.spacing(6),
        color: 'white',
      },
      errorStatus: {
        marginTop: theme.spacing(3),
        color: '#b71c1c',
      },
      errorStatusOauth: {
        color: '#b71c1c',
      },
      loginEmailContainer: {
        margin: theme.spacing(0, 0, 3, 0),
        width: '70%',
        transition: 'all 0.4s ease-in-out',
      },
      divHide: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
      },
      divShow: {
        opacity: 1,
        height: 'auto',
      },
    })
  })

const LoginForm: FC<Props> = ({
  onSubmit,
  loginFailMsg,
  eventCodeFromQuery,
  accessCodeFromQuery,
  providers,
}) => {
  const classes = useStyles(providers?.providers)()
  const { t } = useTranslation()

  // Initialization of State value
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [eventName, setEventName] = useState<string>(eventCodeFromQuery)
  const [privacySetting, setPrivacySetting] = useState<boolean>(false)
  const [localStorageSetting, setLocalStorageSetting] = useState<boolean>(false)
  const [showEmailLogin, setShowEmailLogin] = useState<boolean>(false)

  // Constants initialization
  const OAuth2URL = authService.servicePrefix + '/oauth2'
  const privacyURL = 'https://www.accenture.com/us-en/about/privacy-policy'
  const cookieLinkClick =
    'https://www.accenture.com/us-en/support/company-cookies-similar-technology'
  const label = { 'aria-label': 'Checkbox' }

  const showEmailLoginContainer = () => {
    setShowEmailLogin(!showEmailLogin)
  }

  const handleLocalStorage = () => {
    setLocalStorageSetting(!localStorageSetting)
  }

  const handleChange = () => {
    setPrivacySetting(!privacySetting)
  }
  useEffect(() => {
    if (eventCodeFromQuery) {
      localStorageService.setLocalStorageItemValue('eventCode', JSON.stringify(eventName))
    }
    if (accessCodeFromQuery) {
      localStorageService.setLocalStorageItemValue(
        'accessCode',
        JSON.stringify(accessCodeFromQuery)
      )
    }
  }, [])

  const setEventLocalStorage = () => {
    if (!eventCodeFromQuery) {
      localStorageService.setLocalStorageItemValue('eventCode', JSON.stringify(eventName))
    }
  }

  useEffect(() => {}, [username, password, eventName])

  // On Submit button click
  const onClick = (e: any) => {
    e.preventDefault()
    onSubmit(username, password, eventName)
  }

  // On SSO login button click
  const onSSOLogInClick = (SSOType: string) => {
    const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
    if (traverseRedirect) {
      return OAuth2URL + '?frontend=' + FrontendTypes.TRAVERSE + '&provider=' + SSOType
    } else {
      return OAuth2URL + '?frontend=' + FrontendTypes.VISITOR + '&provider=' + SSOType
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        {eventName === 'theoceanrace2023' ? (
          <>
            <Typography className={classes.header} data-testid='title' variant={'h4'}>
              {'Welcome to The Ocean Race Metaverse!'}
            </Typography>
            <p className={classes.oceanRaceCaption}>
              {'To access our metaverse for the first time, you’ll need to register for a code '}
              <Link
                href={'https://theoceanrace.qualifioapp.com/quiz/1210611_2826/.html'}
                target='_blank'
                className={`${classes.smallCaption}`}
                id='disclaimer--link'
              >
                {'here'}
              </Link>
            </p>
            <p className={classes.oceanRaceCaption}>
              {
                'If you already have your access code or have logged in before, please click one of the below:'
              }
            </p>
          </>
        ) : (
          <div className={classes.titleContainer}>
            <Typography>
              <div className={classes.titleContainerChild}>
                {t('authPages.logInContainer.title')}
              </div>
            </Typography>
          </div>
        )}
        <div className={classes.inputContainer}>
          <TextField
            placeholder={t('authPages.logInForm.eventCode.placeholder')}
            variant='standard'
            color='primary'
            autoComplete={'off'}
            fullWidth={true}
            value={eventName}
            id='login-form-event-code'
            InputProps={{ classes: { input: classes.input } }}
            onChange={(e) => setEventName(e.target.value)}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Typography className={`${classes.checkboxContainerTypography}`}>
            <Box className={`${classes.checkboxContainerBox}`}>
              <Checkbox
                className={`${classes.checkboxClass}`}
                value={privacySetting}
                onChange={handleChange}
                inputProps={label}
                id='privacy-policy-link-checkbox'
                color='default'
              />
              <div className={classes.checkboxContainerSpan}>
                <span>{t('authPages.logInForm.consentRequirements.message')}</span>
                <Link
                  target='_blank'
                  className={`${classes.checkboxContainerLink}`}
                  underline='always'
                  href={privacyURL}
                  key={privacyURL}
                  id='privacy-policy-link'
                >
                  {t('authPages.logInForm.consentRequirements.privacyPolicy')}
                </Link>
              </div>
            </Box>
            <Box className={`${classes.checkboxContainerBox}`}>
              <Checkbox
                className={`${classes.checkboxClass}`}
                value={localStorageSetting}
                onChange={handleLocalStorage}
                inputProps={label}
                id='local-storage-login-checkbox'
                color='default'
              />
              <span className={`${classes.checkboxContainerSpan}`}>
                {t('authPages.logInForm.localStorageConsent.localStorageMessage')}
              </span>
            </Box>
          </Typography>
        </div>
        <div className={`${classes.buttonContainer}`}>
          {providers &&
            providers.providers.map((provider) => {
              return (
                <div key={provider.name} className={`${classes.providerButton}`}>
                  <Button
                    id={provider.button_description}
                    variant='contained'
                    className={`${classes.button}`}
                    disabled={!(privacySetting && localStorageSetting)}
                    onClick={() => setEventLocalStorage()}
                    href={onSSOLogInClick(provider.name)}
                  >
                    <Typography className={`${classes.buttonSpan}`}>
                      {provider.button_text}
                    </Typography>
                  </Button>
                </div>
              )
            })}
          {!showEmailLogin && (
            <div className={`${classes.providerButton}`}>
              <Button
                id='login-via-email'
                variant='contained'
                className={`${classes.button}`}
                onClick={() => showEmailLoginContainer()}
              >
                <Typography className={`${classes.buttonSpan}`}>
                  {t('authPages.logInForm.loginTitles.loginViaEmail')}
                </Typography>
              </Button>
            </div>
          )}
        </div>
        <div
          className={`${classes.loginEmailContainer} 
                            ${showEmailLogin ? classes.divShow : classes.divHide}`}
        >
          <form noValidate onSubmit={onClick}>
            <div>
              <TextField
                placeholder={t('authPages.logInForm.email.placeholder')}
                variant='standard'
                color='primary'
                fullWidth={true}
                value={username}
                id='login-form-email'
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  classes: { input: classes.inputCredentials },
                }}
                autoComplete={'email'}
              />
            </div>
            <div>
              <TextField
                placeholder={t('authPages.logInForm.password.placeholder')}
                variant='standard'
                color='primary'
                fullWidth={true}
                type='password'
                id='login-form-password'
                value={password}
                InputProps={{
                  classes: { input: classes.inputCredentials },
                }}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete={'off'}
              />
            </div>
            {loginFailMsg && (
              <div>
                <Typography variant='subtitle2' className={classes.errorStatus}>
                  {loginFailMsg}
                </Typography>
              </div>
            )}
            <div className={classes.providerButton}>
              <Button
                variant='contained'
                type={'submit'}
                id='login-form-submit'
                className={`${classes.button}`}
                onClick={onClick}
                disabled={!(privacySetting && localStorageSetting)}
              >
                <Typography className={`${classes.buttonSpan}`}>
                  {t('authPages.logInForm.loginTitles.login')}
                </Typography>
              </Button>
            </div>
          </form>
        </div>
        <div className={classes.headerTitleThird}>
          <Box>
            <div>{t('authPages.logInForm.consentRequirements.personalData')} </div>
            <div>{t('authPages.logInForm.consentRequirements.personalData2')} </div>
            <div>
              {t('authPages.logInForm.consentRequirements.personalData3')}
              <Link
                target='_blank'
                className={`${classes.smallCaption}`}
                underline='always'
                href={privacyURL}
                key={privacyURL}
                id='privacy-policy-link'
              >
                {t('authPages.logInForm.consentRequirements.privacyStatement')}
              </Link>
              {t('authPages.logInForm.consentRequirements.importantInformation')}
            </div>
          </Box>
          <Box>
            <div>
              {t('authPages.logInForm.cookieConsent.cookieMessage')}
              <Link
                target='_blank'
                className={`${classes.smallCaption}`}
                underline='always'
                href={cookieLinkClick}
                key={cookieLinkClick}
                id='cookie-policy-link'
              >
                {t('authPages.logInForm.cookieConsent.cookiePolicy')}
              </Link>
            </div>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
