import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { RoomChatPages, switchRoomChatPage } from '../../Containers/RoomChat/RoomChatSlice'
import { MenuPages, switchMenuPage, toggleMenu } from '../../Containers/Menu/MenuSlice'
import { Conversation, User, localStorageService } from '../../Services/services-index'
import { FormatText } from '../../Common/common-index'
import {
  ChatPages,
  switchChatPage,
  updateActiveConversation,
  updateActiveConversationId,
} from '../../Containers/Chat/ChatSlice'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      minWidth: '150px',
      maxWidth: '400px',
      padding: '5px',
      height: 'fit-content',
      boxShadow: '0px 0px 5px #0000002E',
      position: 'relative',
      bottom: '0px',
      width: 'fit-content',
      margin: theme.spacing(0, 0, 2, 2),
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        bottom: '0px',
      },
    },
    wrapper: {
      display: 'inline-block',
      margin: theme.spacing(1),
    },
    message: {
      alignSelf: 'start',
      display: 'inline-block',
      fontSize: '18px',
      fontFamily: 'Graphik',
      textAlign: 'start',
      wordBreak: 'break-word',
      color: 'white',
    },
    userInfo: {
      color: theme.palette.grey[200],
      display: 'inline-block',
      marginLeft: '2px',
    },
  })
)

export const RoomChatFeed: FC<any> = ({ children, appearance }) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const roomConversations = useAppSelector((state) => state.roomChat.conversations)
  const newsfeedNotificationsOn = useAppSelector((state) => state.settings.newsfeedNotificationsOn)

  const [activeParticipants, setActiveParticipants] = useState<User[]>()
  const [showRoomChatFeed, setShowRoomChatFeed] = useState<boolean>(true)

  useEffect(() => {
    const roomChatFeedState: any =
      localStorageService.getLocalStorageItemValue('enableRoomChatFeed')
    if (roomChatFeedState) {
      roomChatFeedState === 'true' ? setShowRoomChatFeed(true) : setShowRoomChatFeed(false)
    }
  }, [showRoomChatFeed, newsfeedNotificationsOn])

  useEffect(() => {
    if (roomConversations && Object.entries(roomConversations).length) {
      const [value] = Object.entries(roomConversations)
      const { participants } = value[1]
      setActiveParticipants(participants)
    }
  }, [activeParticipants?.length])

  const onRoomFeedClick = () => {
    reSetConversation()
    dispatch(toggleMenu(true))
    dispatch(switchMenuPage(MenuPages.RoomChat))
    dispatch(switchRoomChatPage(RoomChatPages.RoomChatMessage))
  }

  // To reset conversation state
  const reSetConversation = () => {
    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))
    dispatch(switchChatPage(ChatPages.ChatList))
  }

  return (
    <>
      {showRoomChatFeed && children && appearance && (
        <div id='room-feed-container' className={classes.root} onClick={onRoomFeedClick}>
          <div className={classes.wrapper}>
            <Typography variant={'body1'} className={classes.message}>
              <FormatText text={children} />
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

export default RoomChatFeed
