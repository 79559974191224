import React, { FC } from 'react'
import { Link, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { FileUpload, Icon } from '../../Common/common-index'
import UserAvatar from '../../Containers/UserAvatar/UserAvatar'

interface Props {
  currentUserId?: number
  errorMessage?: string
  successMessage?: string
  userHasProfilePicture?: boolean
  // eslint-disable-next-line no-unused-vars
  updateProfilePicture?: (e: any) => void
  removeProfilePicture?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    height: '100%',
  },
  title: {
    fontSize: '15px',
    padding: theme.spacing(4, 0),
    color: 'white',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
  },
  menuItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    width: '50%',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5%',
  },
  listItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  menuItem: {
    fontSize: '14px',
    lineHeight: '17px',
    color: 'white',
  },
  icon: {
    marginRight: '12px',
  },
  defaultColor: {
    color: '#000000',
  },
  defaultImage: {
    opacity: 0.5,
    cursor: 'default',
    pointerEvents: 'none',
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    marginTop: theme.spacing(5),
  },
  successStatus: {
    color: theme.palette.primary.main,
  },
}))

export const ChangeProfilePicture: FC<Props> = ({
  currentUserId,
  errorMessage,
  successMessage,
  userHasProfilePicture,
  updateProfilePicture,
  removeProfilePicture,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <div className={classes.paperContainer}>
        <Typography variant={'subtitle2'} className={classes.title} data-testid='title'>
          {t('profileSettingsPage.changePicturePage.profilePicture')}
        </Typography>
        <div className={classes.contentWrapper}>
          <div className={classes.imageWrapper}>
            <UserAvatar userId={currentUserId} hasUnreadMessage={false} width={99} height={99} />
          </div>
          <div className={classes.menuItemsWrapper}>
            <div className={classes.listItemWrapper}>
              <div className={classes.icon}>
                <Icon iconName={'upload'} />
              </div>
              <div data-testid='update-profile' role={'button'} id='profile-change-picture-upload'>
                <FileUpload
                  selectedFile={updateProfilePicture}
                  title={t('profileSettingsPage.changePicturePage.uploadPicture')}
                />
              </div>
            </div>
            {/* The following style code should be removed once the feature is valid */}
            <div className={classes.listItemWrapper} style={{ display: 'none' }}>
              <div className={classes.icon}>
                <Icon iconName={'photoCamera'} />
              </div>
              <Link variant={'body2'} className={classes.menuItem}>
                {t('profileSettingsPage.changePicturePage.takeAPhoto')}
              </Link>
            </div>
            <div
              className={
                userHasProfilePicture === false
                  ? `${classes.listItemWrapper} ${classes.defaultImage}`
                  : classes.listItemWrapper
              }
            >
              <div className={classes.icon}>
                <Icon iconName={'trash'} />
              </div>
              <Link
                id='profile-change-picture-remove-link'
                data-testid='remove-profile'
                variant={'body2'}
                role={'button'}
                className={`${classes.menuItem}`}
                onClick={removeProfilePicture}
              >
                {t('profileSettingsPage.changePicturePage.removePicture')}
              </Link>
            </div>
          </div>
        </div>
        {(errorMessage || successMessage) && (
          <Typography
            variant='body2'
            className={
              errorMessage ? classes.errorStatus : `${classes.errorStatus} ${classes.successStatus}`
            }
          >
            {errorMessage || successMessage}
          </Typography>
        )}
      </div>
    </Slide>
  )
}

export default ChangeProfilePicture
