import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

export interface InputProps {
  selectedFile: any
  title?: string
}

const useStyles = makeStyles(() => ({
  blue: { color: 'white', cursor: 'pointer' },
  hidden: { display: 'none' },
}))

export const FileUpload: FC<InputProps> = ({ selectedFile, title }) => {
  const classes = useStyles()

  const handleInput = (e: any) => {
    selectedFile(e)
    //setting value of target to null so onChange event is triggered when user uploads the same picture after removing it
    e.currentTarget.value = null
  }

  return (
    <div>
      <input
        aria-labelledby='upload-label'
        id='upload-input'
        type='file'
        className={classes.hidden}
        onChange={(event: any) => handleInput(event)}
      />
      <label htmlFor='upload-input'>
        <Link variant={'body2'} className={classes.blue} id='upload-label'>
          {title}
        </Link>
      </label>
    </div>
  )
}
