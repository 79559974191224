import { createStyles, ListItem, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButton: {
      color: 'white',
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.main,
      },
      marginRight: '15px',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
      width: '100%',
    },
    avatarImg: {
      objectFit: 'contain',
      flex: 1,
      width: '30%',
      height: '30%',
      aspectRatio: '1.5',
    },
    name: {
      margin: '10px',
      color: 'black',
      fontSize: '14px',
      flex: 2,
    },
    innerDiv: {
      flex: 1,
    },
  })
)

export interface Props {
  value: string
  label: any
  path?: any
  onClickEvent: () => void
  checkSelected: () => boolean
}

export const ToggleItemButton: FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <ListItem
      button
      selected={props.checkSelected()}
      onClick={() => props.onClickEvent()}
      className={classes.radioButton}
    >
      {props.path ? (
        <>
          <div className={classes.container}>
            <img alt={props.label} src={props.path} className={classes.avatarImg} />
            <Typography className={classes.name}>{props.label}</Typography>
          </div>
        </>
      ) : (
        props.label
      )}
    </ListItem>
  )
}
