import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import { FormInputField } from '../../Common/common-index'

interface Props {
  phoneNumberValue?: string
  emailValue?: string
  textAreaInput?: string
  phoneNumberFieldHasError?: boolean
  emailFieldHasError?: boolean
  errorMessage?: string
  // eslint-disable-next-line no-unused-vars
  onPhoneNumberChange: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  onEmailChange: (e: any) => void
  onTextAreaValueChange: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& div[class*="MuiFormControl-root"]': {
        '& input': {
          fontSize: '14px',
          fontFamily: 'Graphik-Regular',
        },
        '& label': {
          fontSize: '14px',
          fontFamily: 'Graphik-Regular',
        },
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      width: '100%',
    },
    headerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    inputItem: {
      marginTop: '30px',
    },
    input: {
      ...theme.typography.caption,
      flex: 1,
      height: '36px',
      width: '100%',
    },
    pageTitle: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: 'white',
    },
    pageDescription: {
      marginBottom: theme.spacing(5),
      fontFamily: 'Graphik',
      lineHeight: '25px',
      fontSize: '16px',
      color: 'white',
    },
    errorStatus: {
      marginTop: theme.spacing(5),
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      flexDirection: 'row-reverse',
      lineHeight: '16px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
      },
    },
    textarea: {
      ...theme.typography.caption,
      fontFamily: 'Graphik',
      fontSize: '14px',
      minWidth: '230px',
      minHeight: '100px',
      maxHeight: '400px',
      marginTop: '60px',
      resize: 'vertical',
      width: 'calc(100% - 36px)',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      color: 'white',
    },
  })
)

const HelpDeskForm: FC<Props> = ({
  phoneNumberValue,
  emailValue,
  textAreaInput,
  phoneNumberFieldHasError,
  emailFieldHasError,
  errorMessage,
  onPhoneNumberChange,
  onEmailChange,
  onTextAreaValueChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <form className={classes.root}>
        <div className={classes.headerWrapper}>
          <Typography variant={'h6'} className={classes.pageTitle}>
            {t('helpMenu.helpDesk.pageTitle')}
          </Typography>
          <Typography variant={'body1'} className={classes.pageDescription}>
            {t('helpMenu.helpDesk.pageDescription')}
          </Typography>
        </div>
        <Grid
          className={classes.headerWrapper}
          container
          direction='column'
          justifyContent='center'
        >
          <Grid item xs={12}>
            <FormInputField
              id='help-contact-phone'
              placeholder={t('helpMenu.helpDesk.phonePlaceholder')}
              value={phoneNumberValue}
              isPasswordField={false}
              autoComplete={'phone'}
              inputProps={{
                maxLength: 2000,
              }}
              error={phoneNumberFieldHasError}
              errorMessage={t('helpMenu.helpDesk.errorMessagePhone')}
              onChange={onPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <FormInputField
              id='help-contact-email'
              placeholder={t('helpMenu.helpDesk.emailPlaceholder')}
              value={emailValue}
              isPasswordField={false}
              autoComplete={'email'}
              inputProps={{
                maxLength: 2000,
              }}
              error={emailFieldHasError}
              errorMessage={t('helpMenu.helpDesk.errorMessageEmail')}
              onChange={onEmailChange}
            />
          </Grid>
        </Grid>
        <TextareaAutosize
          id='help-contact-question'
          className={classes.textarea}
          minRows={4}
          placeholder={t('helpMenu.helpDesk.textPlaceholder')}
          value={textAreaInput}
          maxLength={2000}
          onChange={onTextAreaValueChange}
        />
        {errorMessage && errorMessage.length && (
          <Typography variant='body2' className={classes.errorStatus}>
            {errorMessage}
          </Typography>
        )}
      </form>
    </Slide>
  )
}

export default HelpDeskForm
