import React, { FC } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'
import { CircleIconButton } from '../../Common/Components/Buttons/CircleIconButton'
import UserAvatar from '../../Containers/UserAvatar/UserAvatar'
import { User } from '../../Services/services-index'
import { CallType } from '../../Services/Models/CallModel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      zIndex: 1000,
      padding: 20,
      borderRadius: '8px',
      maxWidth: '600px',
      width: '33%',
      minWidth: '400px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      minHeight: '376px',
    },
    dialogTitle: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Graphik-Semibold',
      textAlign: 'center',
    },
    dialogContentTop: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    dialogContent: {
      fontFamily: 'Graphik-Regular',
      fontSize: '21px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '20px',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'row',
      },
    },
    buttonContainer: {
      width: '50%',
      minWidth: '225px',
      justifyContent: 'space-between',
      display: 'flex',
    },
    disableAction: {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: '0.5',
    },
    errorStatus: {
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: '-21px',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        margin: '16px',
        justifyContent: 'center',
      },
    },
    successStatus: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        transform: 'none',
        backgroundColor: theme.palette.primary.main,
      },
    },
    actionButton: {
      margin: 0,
      padding: 0,
    },
    declineCallIcon: {
      transform: 'rotate(135deg)',
    },
    videoIcon: {
      paddingTop: '18px',
    },
  })
)

export interface IncomingCallDialogProps {
  open: boolean
  onCancel: Function
  onConfirm: Function
  callerIds: number[]
  caller: User
}

export const IncomingCallDialog: FC<IncomingCallDialogProps> = ({
  open = false,
  onCancel,
  onConfirm,
  callerIds,
  caller,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = (type: CallType) => {
    onConfirm(type)
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='incoming-call-dialog-title'
      open={open}
    >
      <DialogTitle
        id='incoming-call-dialog-title'
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography variant={'h3'} style={{ color: 'white' }}>
          {t('incomingCallDialog.incomingCallTitle')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentTop}>
        <UserAvatar
          userId={caller?.id ? caller.id : undefined}
          isGroup={callerIds?.length > 1}
          width={120}
          height={120}
          tooltipPlacement='bottom'
        />
      </DialogContent>
      <DialogContent className={classes.dialogContent} style={{ color: 'white' }}>
        {caller?.firstname} {caller?.lastname}
        {callerIds?.length > 1 ? ' + ' + (callerIds.length - 1) : ''}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }} disableSpacing>
        <div className={classes.buttonContainer}>
          <CircleIconButton
            id='answer-video-call'
            onClick={() => {
              handleConfirm('video')
            }}
            iconName={'camera'}
            iconButtonProps={{
              'aria-label': 'answer with video',
            }}
            title={'Answer video'}
            classes={`${classes.actionButton} ${classes.button}`}
            iconWrapperClasses={classes.videoIcon}
          />
          <CircleIconButton
            id='answer-audio-call'
            onClick={() => {
              handleConfirm('audio')
            }}
            iconName={'phone'}
            iconButtonProps={{
              'aria-label': 'answer with audio only',
            }}
            title={'Answer audio'}
            classes={classes.button}
          />
          <CircleIconButton
            id='decline-call'
            onClick={handleCancel}
            iconName={'phone'}
            iconButtonProps={{
              'aria-label': 'decline call',
            }}
            title={'Decline call'}
            classes={classes.button}
            iconWrapperClasses={classes.declineCallIcon}
          />
        </div>
      </DialogActions>
    </Dialog>
  )
}
