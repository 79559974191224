import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React, { FC, JSXElementConstructor, ReactElement, ReactNode } from 'react'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export interface MenuListItemProps {
  id: string
  text: string
  startAvatar?: ReactElement<any, string | JSXElementConstructor<any>>
  startIcon?: ReactNode
  endEl?: ReactNode
  count?: number | undefined
  hasUnreadMessage?: boolean
  onClick?: () => void
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
    },
    startEl: {
      alignItems: 'center',
      color: 'white',
      justifyContent: 'center',
      margin: theme.spacing(2.5),
      minWidth: 40,
    },
    text: {
      fontSize: 18,
    },
    endEI: {
      alignItems: 'center',
      backgroundcolor: 'black',
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '40px',
      maxWidth: '65px ',
      paddingLeft: '8px',
      marginRight: '20px',
    },
    count: {
      color: 'white',
      marginRight: '8px',
      fontFamily: 'Grafik',
    },
    groupIcon: {
      '& path[class*="Group_svg__a"]': {
        fill: theme.palette.background.default,
      },
    },
    unread: {
      background: '#54b8f9',
      border: '1px solid white',
      borderRadius: '50%',
      height: '12px',
      position: 'relative',
      top: '10px',
      right: '60px',
      width: '12px',
      zIndex: 2,
    },
  })
)

const MenuListItem: FC<MenuListItemProps> = ({
  id,
  text,
  startAvatar,
  startIcon,
  endEl,
  count,
  hasUnreadMessage,
  onClick,
}) => {
  const classes = styles()

  return (
    <ListItem
      id={id}
      disableGutters
      alignItems='flex-start'
      classes={{ root: classes.root }}
      button
      onClick={onClick}
    >
      {startAvatar && <ListItemAvatar className={classes.startEl}>{startAvatar}</ListItemAvatar>}
      {startIcon && <ListItemIcon className={classes.startEl}>{startIcon}</ListItemIcon>}
      {hasUnreadMessage && <div className={classes.unread}></div>}
      <ListItemText
        primary={
          <Typography variant={'body1'} style={{ color: 'white' }} className={classes.text}>
            {text}
          </Typography>
        }
      />
      <div className={count || count === 0 ? classes.endEI : ''}>
        {count && (
          <Typography variant={'body1'} color={'primary'} className={classes.count}>
            {count}
          </Typography>
        )}
        {endEl && (
          <div className={classes.groupIcon}>
            <ListItemIcon>{endEl}</ListItemIcon>
          </div>
        )}
      </div>
    </ListItem>
  )
}

export default MenuListItem
