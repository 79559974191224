import React, { FC } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useLocation } from 'react-router-dom'
interface Props {
  showRegisterPageInfo?: boolean
  showLogInPageInfo?: boolean
  showForgotPasswordInfo?: boolean
  hasEventBaseDeskEmailId?: boolean
  eventBaseHelpDeskEmailId?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: '#1E1E1E',
    },
    contentItem: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '40px',
      color: 'white',
    },
    topSpace: {
      marginTop: theme.spacing(5),
    },
    rightSpace: {
      marginRight: 3,
    },
    minWidth: {
      minWidth: '82px',
    },
    link: {
      cursor: 'pointer',
      color: '#85adff',
    },
    smallCaption: {
      fontFamily: 'Graphik-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '17px',
    },
    emailHelp: {
      margin: theme.spacing(5, 0),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    and: {
      display: 'flex',
      alignSelf: 'end',
    },
    emailId: {
      color: '#85adff',
    },
    inLine: {
      display: 'inline',
    },
  })
)

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

function getEventCodeQuery(query: URLSearchParams) {
  const eventCodeQuery = query.get('event')
  let eventCode: string
  if (!eventCodeQuery) {
    eventCode = ''
  } else {
    eventCode = eventCodeQuery
  }

  return eventCode
}

export const AuthPageFooter: FC<Props> = ({
  showRegisterPageInfo,
  showLogInPageInfo,
  showForgotPasswordInfo,
  hasEventBaseDeskEmailId,
  eventBaseHelpDeskEmailId,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const query = useQuery()
  const eventCode = getEventCodeQuery(query)

  // Constants initialization
  const defaultHelpDeskId = 'STAR.helpdesk@accenture.com'
  const onPrivacyLinkClick = 'https://www.accenture.com/us-en/about/privacy-policy'

  // On click page re-routing
  const onLogInClick = () => '/auth/login'
  const onForgotPassword = () => '/auth/forgot-password'
  const onCreateAccountClick = () => '/auth/registration'
  const onTermsOfUseClick = require('../../../Assets/documents/PolicyTerms.pdf').default
  //Remove after ocean race
  const onTermsOfUseOceanRaceClick =
    require('../../../Assets/documents/PolicyTermsOceanRace.pdf').default

  // On STAR Support email click
  const onSupportEmailIdClick = (emailId: any) => {
    const encodedSupportEmailId = encodeURI(emailId)
    const emailData = `mailto:${encodedSupportEmailId}`
    window.open(emailData, '_self', 'noopener,noreferrer')
  }

  return (
    <div className={classes.container}>
      {showRegisterPageInfo && (
        <Box component='span' mt={5}>
          <Typography data-testid='title' variant='body1'>
            {t('authPages.authFooter.dontHaveAccount')}
          </Typography>
          <Link
            variant='h5'
            href={onCreateAccountClick()}
            key={onCreateAccountClick()}
            id='create-account-link'
          >
            {t('authPages.logInForm.button.createAccount')}
          </Link>
        </Box>
      )}
      {showLogInPageInfo && (
        <Box component='span' mt={5}>
          <Typography variant='body1'>{t('authPages.authFooter.haveAccount')}</Typography>
          <Link variant='h5' href={onLogInClick()} key={onLogInClick()} id='auth-footer-login-link'>
            {t('authPages.logInForm.button.login')}
          </Link>
        </Box>
      )}
      <Box className={classes.contentItem}>
        {eventCode === 'theoceanrace2023' ? (
          <Link
            target='_blank'
            variant='caption'
            className={`${classes.smallCaption} ${classes.link} ${classes.rightSpace}`}
            href={onTermsOfUseOceanRaceClick}
            key={onTermsOfUseOceanRaceClick}
            id='terms-of-use-link'
          >
            {t('authPages.authFooter.termOfUse')}
          </Link>
        ) : (
          <Link
            target='_blank'
            variant='caption'
            className={`${classes.smallCaption} ${classes.link} ${classes.rightSpace}`}
            href={onTermsOfUseClick}
            key={onTermsOfUseClick}
            id='terms-of-use-link'
          >
            {t('authPages.authFooter.termOfUse')}
          </Link>
        )}
        <Typography className={`${classes.smallCaption} ${classes.and} ${classes.rightSpace}`}>
          {t('authPages.authFooter.and')}
        </Typography>
        <Link
          target='_blank'
          className={`${classes.smallCaption} ${classes.link} ${classes.minWidth}`}
          href={onPrivacyLinkClick}
          key={onPrivacyLinkClick}
          id='privacy-policy-link'
        >
          {t('authPages.authFooter.privacyPolicy')}
        </Link>
      </Box>
      {showForgotPasswordInfo && (
        <Link
          className={`${classes.smallCaption} ${classes.link} ${classes.topSpace}`}
          href={onForgotPassword()}
          key={onForgotPassword()}
          id='forgot-password-link'
        >
          {t('authPages.logInForm.button.forgotPassword')}
        </Link>
      )}
      <Box className={`${classes.contentItem} ${classes.emailHelp}`}>
        <Typography className={`${classes.smallCaption} ${classes.rightSpace}`}>
          {t('authPages.authFooter.helpInfo')}
        </Typography>
        <Link
          className={`${classes.smallCaption} ${classes.link} ${classes.emailId}`}
          onClick={() =>
            onSupportEmailIdClick(
              hasEventBaseDeskEmailId ? eventBaseHelpDeskEmailId : defaultHelpDeskId
            )
          }
          id='email-support-link'
        >
          {hasEventBaseDeskEmailId ? eventBaseHelpDeskEmailId : defaultHelpDeskId}
        </Link>
      </Box>
    </div>
  )
}
export default AuthPageFooter
