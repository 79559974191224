import React, { FC, useEffect, useState } from 'react'
import { Socket } from 'socket.io-client'
import { ChatMessageInput } from '../../Common/common-index'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { sendMessageAsync, sendRoomMessageAsync } from '../../Reducers/socketSlice'
import { MessagePayload, MessagingTopics, User } from '../../Services/services-index'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { selectCurrentUser } from '../../Reducers/authSlice'

export interface ChatInputProps {
  enableAutoScroll?: boolean
  hasNewMessageArrived?: boolean
  bottomButtonTitle?: string
  onBottomReturn?: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrapper: {
      alignItems: 'center',
      backgroundColor: 'white',
      border: '1px solid white',
      borderRadius: '20px',
      boxShadow: '0px 0px 10px #0000002E',
      bottom: '170px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      padding: '0 20px',
      justifyContent: 'center',
      position: 'absolute',
      transform: 'translateX(-50%)',
      height: '40px',
      width: 'fit-content',
    },
    roomChatButton: {
      bottom: '100px',
    },
    buttonTitle: {
      color: 'black',
      fontFamily: 'Graphik-Regular',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '22px',
    },
  })
)

const ChatMessageInputContainer: FC<ChatInputProps> = ({
  enableAutoScroll,
  bottomButtonTitle,
  hasNewMessageArrived,
  onBottomReturn,
}) => {
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const socket: Socket | null = useAppSelector((state) => state.socket.socket)
  const currentUser = useAppSelector(selectCurrentUser)
  const activeConversation = useAppSelector((state) => state.chat.activeConversation)
  const isRoomConversation = useAppSelector((state) => state.roomChat.isRoomConversation)
  const roomId = useAppSelector((state) => state.room.activeRoomId)

  const [inputValue, setInputValue] = useState('')
  const [botId, setBotId] = useState<any>()

  const [activeParticipants, setParticipants] = useState<User[]>([])

  const [socketMessageResponse, setSocketMessageResponse] = useState<MessagePayload>()

  const [socketBotID, setSocketBotId] = useState<any>()

  const handleChange = (value: string) => {
    setInputValue(value)
  }

  useEffect(() => {
    if (activeConversation) {
      setParticipants(activeConversation?.participants)
      setBotId(activeConversation?.bot)
      getSocketBotID()
    }
  }, [activeConversation?.participants?.length, activeParticipants, roomId, botId])

  const getSocketBotID = () => {
    if (currentUser && socket) {
      const { event_code } = currentUser
      const chatTopic = `${MessagingTopics.CHAT}_${event_code}`
      socket.on(chatTopic, (data: string) => {
        const parsedResponse = JSON.parse(data)
        setSocketMessageResponse(parsedResponse)
      })

      if (socketMessageResponse) {
        const socketBotId = socketMessageResponse.bot
        if (socketBotId) {
          setSocketBotId(socketMessageResponse.bot)
        }
      }
    }
  }

  const chatMessageSubmit = () => {
    if (socket !== null && currentUser) {
      const receiverIds: number[] = []
      const { id: currentUserId } = currentUser
      if (activeParticipants && currentUserId) {
        activeParticipants
          .filter((p) => p.id !== currentUserId)
          .forEach((participant) => {
            if (participant.id) {
              receiverIds.push(participant.id)
            }
          })

        //chat with chatbot
        if (botId) {
          if (inputValue?.length && socketBotID) {
            dispatch(sendMessageAsync({ receiverIds, inputValue, botId: botId }))
            setInputValue('')
          }
          //chat with normal user
        } else if (inputValue?.length) {
          dispatch(sendMessageAsync({ receiverIds, inputValue, botId: botId }))
          setInputValue('')
        }
      }
    }
  }

  const roomMessageSubmit = () => {
    if (socket !== null) {
      const receiverIds: number[] = []
      if (isRoomConversation && inputValue?.length && roomId) {
        dispatch(sendRoomMessageAsync({ receiverIds, inputValue, roomId }))
        setInputValue('')
      }
    }
  }

  const renderBottomButtons = () => {
    return (
      <>
        <div
          className={
            isRoomConversation
              ? `${classes.buttonWrapper} ${classes.roomChatButton}`
              : classes.buttonWrapper
          }
          onClick={onBottomReturn}
        >
          <div className={classes.buttonTitle}>{bottomButtonTitle} </div>
        </div>
      </>
    )
  }

  return (
    <>
      {!enableAutoScroll &&
        (hasNewMessageArrived || !hasNewMessageArrived) &&
        bottomButtonTitle?.length &&
        renderBottomButtons()}
      <ChatMessageInput
        isSendButtonDisabled={
          !inputValue?.length || (isRoomConversation && (roomId === undefined || !roomId))
            ? true
            : false
        }
        inputValue={inputValue}
        maxLength={2000}
        onSubmit={isRoomConversation ? roomMessageSubmit : chatMessageSubmit}
        onChange={handleChange}
      />
    </>
  )
}

export default ChatMessageInputContainer
