import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import InputBase from '@material-ui/core/InputBase/InputBase'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../common-index'

export interface ChatMessageInputProps {
  inputValue?: string
  isSendButtonDisabled?: boolean
  maxLength?: number
  // eslint-disable-next-line no-unused-vars
  onSubmit: () => void
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWrapper: {
      backgroundColor: theme.palette.background.paper,
      padding: '20px 10px',
      '& input[class*="MuiInputBase-input"]': {
        padding: 0,
      },
    },
    input: {
      ...theme.typography.caption,
      paddingLeft: 20,
      width: '100%',
      height: 36,
      borderRadius: 18,
      backgroundColor: 'white',
      flex: 1,
    },
    icon: {
      padding: 0,
      marginLeft: theme.spacing(1),
      marginRight: '2px',
      '& svg': {
        height: '32px !important',
        width: '32px !important',
      },
    },
    disableView: {
      opacity: '0.35',
      cursor: 'default',
    },
  })
)

export const ChatMessageInput: FC<ChatMessageInputProps> = ({
  inputValue = '',
  isSendButtonDisabled,
  maxLength,
  onSubmit,
  onChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <div className={classes.inputWrapper}>
      <InputBase
        id='chat-message-input'
        onKeyPress={onKeyPress}
        className={classes.input}
        placeholder={t('chatPage.chatMessageInput.placeholder')}
        inputProps={{
          'aria-label': `${t('chatPage.chatMessageInput.placeholder')}`,
          maxLength: maxLength,
        }}
        value={value}
        autoComplete={'off'}
        onChange={handleChange}
        endAdornment={
          <Icon
            id='chat-message-input-submit'
            iconName={'send'}
            iconSize={'32px'}
            onClick={onSubmit}
            iconButtonProps={{
              'aria-label': 'send',
              classes: {
                root:
                  value?.length || !isSendButtonDisabled
                    ? classes.icon
                    : `${classes.icon} ${classes.disableView}`,
              },
              type: 'submit',
            }}
            svgIconProps={{
              color: 'black',
            }}
          />
        }
      />
    </div>
  )
}
