import { Grid, Slide, Typography } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { MenuDrawer, MenuHeader, ShadowedButton } from '../../Common/common-index'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { HelpPages, switchHelpPage } from './helpSlice'

interface Props {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      textAlign: 'start',
      backgroundColor: theme.palette.background.default,
      minWidth: '270px',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      marginTop: '20px',
      padding: '0 32px',
    },
    title: { padding: '15px 0', fontSize: '15px' },
    description: {
      fontSize: '15px',
      color: 'white',
    },
    button: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    userManual: {
      padding: theme.spacing(2, 4),
      marginTop: theme.spacing(5),
      width: '100%',
    },
    errorStatus: {
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      paddingTop: theme.spacing(4),
    },
  })
)

const HelpPrivacy: FC<Props> = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // const [privacySetting, setprivacySetting] = useState<boolean>(false)
  const onPrivacyLinkClick = 'https://www.accenture.com/us-en/about/privacy-policy'
  const dispatch = useAppDispatch()
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }
  const OnGoBack = () => {
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [currentUser])

  function onPolicyPrivacyClick() {
    window.open(onPrivacyLinkClick)
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('helpMenu.privacyPolicy.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <Grid className={classes.container}>
            <Grid container>
              <div className={classes.header}>
                <Typography className={classes.description} variant={'body2'}>
                  {t('helpMenu.privacyPolicy.description')}
                </Typography>
              </div>
              <div className={classes.userManual}>
                <ShadowedButton
                  id='help-user-manual-button'
                  text={t('helpMenu.privacyPolicy.privacyPolicyButton')}
                  buttonStyle={'default'}
                  wrapperClasses={classes.button}
                  onClick={onPolicyPrivacyClick}
                ></ShadowedButton>
              </div>
            </Grid>
          </Grid>
        </Slide>
      </MenuDrawer>
    </form>
  )
}
export default HelpPrivacy
