import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip, TextField, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'

import { Tag } from '../../Services/services-index'
import { Icon } from '../../Common/common-index'
import { createHtmlIdString } from '../../Utils/utils'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    minWidth: '270px',
    height: '100%',
    '& div[class*="MuiFormControl-root"]': {
      '& input': {
        fontSize: '15px',
        fontFamily: 'Graphik-Regular',
      },
      '& label': {
        fontSize: '15px',
        fontFamily: 'Graphik-Regular',
      },
      width: '100%',
    },
    '& button[class*="MuiIconButton-root"]': { paddingRight: 0 },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: '40px',
    padding: '0 32px',
  },
  title: { padding: '15px 0', fontSize: '15px', color: 'white' },
  description: {
    fontSize: '15px',
    color: 'white',
  },
  interests: {
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 0',
    '& div': {
      '&[class*="MuiInput-underline"]:after': {
        borderBottom: theme.palette.grey.A100,
      },
    },
  },
  interestChip: {
    marginTop: '10px',
    backgroundColor: '#904488',
    '& [class*="MuiChip-deleteIcon"]': {
      color: '#ffffff',
      width: '14px',
      height: '14px',
      margin: '10px',
    },
    '& [class*="MuiChip-label"]': {
      paddingRight: 0,
      maxWidth: '100px',
    },
  },
  searchInput: {
    fontFamily: 'Graphik-Regular',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px',
    width: '100%',
    height: '40px',
    backgroundColor: theme.palette.background.default,
  },
  interestName: {
    '& div[class*="MuiChip-root"]': { marginRight: '5px' },
  },
  searchWrapper: {
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: '80px',
  },
  searchSkills: {
    marginBottom: '15px',
    fontSize: '15px',
    color: 'white',
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    padding: theme.spacing(4),
  },
  successStatus: {
    color: 'white',
    fontWeight: 'bold',
  },
}))

interface Props {
  inputValue?: string
  errorMessage?: string
  successMessage?: string
  currentInterests?: Tag[]
  // eslint-disable-next-line no-unused-vars
  changeInputValue?: (e: ChangeEvent<HTMLInputElement>) => void
  addInterest?: () => void
  // eslint-disable-next-line no-unused-vars
  postDelete?: (e: string) => void
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (e: any) => void
}

export const InterestsComponent: FC<Props> = ({
  inputValue,
  errorMessage,
  successMessage,
  currentInterests,
  changeInputValue,
  addInterest,
  postDelete = () => {},
  onKeyDown,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onDelete = (item: string) => {
    postDelete(item)
  }

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <div className={classes.paperContainer}>
        <div className={classes.header}>
          <Typography variant={'subtitle2'} className={classes.title}>
            {t('profileSettingsPage.interestsPage.subTitle1')}
          </Typography>
          <Typography className={classes.description} variant={'body2'}>
            {t('profileSettingsPage.interestsPage.subTitle2')}
          </Typography>
        </div>
        <div className={classes.interests}>
          <div className={classes.interestName} id='profile-interest-chips-container'>
            {currentInterests &&
              currentInterests.map((interest: Tag) => {
                return (
                  <Chip
                    id={createHtmlIdString('profile-interest-chip-', interest?.tag_name)}
                    key={interest?.tag_name}
                    label={interest?.tag_name}
                    title={interest?.tag_name}
                    className={classes.interestChip}
                    onDelete={() => onDelete(interest?.tag_name)}
                  />
                )
              })}
          </div>
        </div>
        <div className={classes.searchWrapper}>
          <Typography className={classes.searchSkills} variant={'subtitle2'}>
            {t('profileSettingsPage.interestsPage.addInterest')}
          </Typography>
          <TextField
            id='profile-interests-add'
            variant='standard'
            placeholder={t('profileSettingsPage.interestsPage.lableName')}
            value={inputValue}
            className={classes.searchInput}
            autoComplete={'off'}
            onChange={changeInputValue}
            inputProps={{
              style: { color: '#ffffff' },
              maxLength: 2000,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon
                    iconName='search'
                    iconSize={'22px'}
                    iconButtonProps={{
                      'aria-label': 'search',
                      disabled: errorMessage,
                    }}
                    onClick={addInterest}
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={onKeyDown}
          />
        </div>
        {(errorMessage || successMessage) && (
          <Typography
            variant='body2'
            className={
              errorMessage ? classes.errorStatus : `${classes.errorStatus} ${classes.successStatus}`
            }
          >
            {errorMessage || successMessage}
          </Typography>
        )}
      </div>
    </Slide>
  )
}
export default InterestsComponent
