import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Slide } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { HelpPages, switchHelpPage } from './helpSlice'
import { contentService, FilesType } from '../../Services/services-index'
import { MenuDrawer, MenuHeader, ShadowedButton } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'start',
    backgroundColor: theme.palette.background.default,
    minWidth: '270px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: '20px',
    padding: '0 32px',
  },
  title: { padding: '15px 0', fontSize: '15px', color: 'white' },
  description: {
    fontSize: '15px',
    color: 'white',
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  userManual: {
    padding: theme.spacing(2, 4),
    marginTop: theme.spacing(5),
    width: '100%',
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    paddingTop: theme.spacing(4),
  },
}))

export const HelpUserManualContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [currentUser])

  // On User Manual click
  const OnUserManualClick = () => {
    if (currentUser) {
      const type = FilesType.USER_MANUAL
      contentService
        .getUserManualFile(type, currentUser?.event_code)
        .then((res: any) => {
          const userManualBlob = new Blob([res?.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(userManualBlob)
          window.open(url)
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  const OnGoBack = () => {
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('helpMenu.userManual.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <Grid className={classes.container}>
            <Grid container>
              <div className={classes.header}>
                <Typography variant={'subtitle2'} className={classes.title}>
                  {t('helpMenu.userManual.subTitle1')}
                </Typography>
                <Typography className={classes.description} variant={'body2'}>
                  {t('helpMenu.userManual.description')}
                </Typography>
              </div>
              <div className={classes.userManual}>
                <ShadowedButton
                  id='help-user-manual-button'
                  text={t('helpMenu.userManual.userMenuButton')}
                  buttonStyle={'default'}
                  wrapperClasses={classes.button}
                  onClick={OnUserManualClick}
                ></ShadowedButton>
                {errorMessage && (
                  <Typography variant='body2' className={classes.errorStatus}>
                    {errorMessage}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Slide>
      </MenuDrawer>
    </form>
  )
}

export default HelpUserManualContainer
