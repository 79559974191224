import React, { FC, ReactElement } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Divider, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormatText } from '../FormatText/FormatText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginBottom: '20px',
      width: '100%',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      wordBreak: 'break-word',
    },
    avatarWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    receivedAvatarIcon: {
      marginRight: '10px',
    },
    bubble: {
      padding: '15px',
      alignItems: 'center',
    },
    receivedBubble: {
      alignSelf: 'flex-start',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '20px 20px 20px 0',
      marginRight: '25px',
      color: 'white',
      '& a': { color: 'black !important' },
    },
    sentBubble: {
      alignSelf: 'flex-end',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '20px 20px 0 20px',
      marginLeft: '25px',
      '& a': { color: 'white' },
    },
    subHeading: {
      whiteSpace: 'nowrap',
      marginTop: '10px',
      alignSelf: 'flex-start',
      color: 'white',
      fontSize: '13px',
    },
    subHeadingSent: {
      alignSelf: 'flex-end',
    },
    businessCard: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: '17px',
      marginBottom: '10px',
    },
    cardDescription: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    actionButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    buttonItem: {
      cursor: 'pointer',
      '&:hover': {
        transform: 'translate(-5px, -3px)',
      },
    },
    cardRequestInfo: {
      display: 'flex',
      justifyContent: 'center',
    },
    denied: {
      pointerEvents: 'none',
      cursor: 'default',
      opacity: '0.75',
    },
    divider: {
      margin: '20px 0',
      background: theme.palette.background.default,
    },
  })
)

export interface ChatMessageProps {
  text?: string
  time: Date
  isReceived?: boolean
  subtitle?: any
  isRoomChat?: boolean
  isGroupChat?: boolean
  avatar?: ReactElement | null
  isBusinessCardMessage?: any
  isBusinessCardApproved?: boolean
  isBusinessCardAllowed?: boolean
  isAllowDisabled?: boolean
  isDenied?: boolean
  allowBusinessCard?: () => void
  denyBusinessCard?: () => void
}

export const ChatMessage: FC<ChatMessageProps> = ({
  text,
  time,
  isReceived,
  subtitle,
  isRoomChat,
  isGroupChat,
  avatar,
  isBusinessCardMessage,
  isBusinessCardApproved,
  isBusinessCardAllowed,
  isAllowDisabled,
  isDenied,
  allowBusinessCard,
  denyBusinessCard,
}) => {
  const classes = useStyles()
  const formattedTime = `${('0' + time.getHours()).slice(-2)}:${('0' + time.getMinutes()).slice(
    -2
  )}`
  const type = isReceived ? 'received' : 'sent'
  const { t } = useTranslation()

  return (
    <span className={`${classes.container} ${classes[type]}`}>
      <div className={avatar ? classes.avatarWrapper : ''}>
        {avatar && (isRoomChat || isGroupChat) && isReceived && (
          <div className={classes.receivedAvatarIcon}>{avatar}</div>
        )}
      </div>
      <span className={`${classes.contentWrapper} ${classes[type + `Avatar`]}`}>
        <div className={`${classes.bubble} ${classes[type + `Bubble`]}`}>
          {!isBusinessCardMessage && text && <FormatText text={text} />}
          {isBusinessCardMessage && (
            <div className={classes.businessCard}>
              <Typography variant={'subtitle2'} className={classes.cardTitle}>
                {t('businessCardsPage.requestCard.message.title')}
              </Typography>
              <Typography variant={'body2'} className={classes.cardDescription}>
                {t('businessCardsPage.requestCard.message.description')}
              </Typography>
              <Divider className={classes.divider} />
              {isReceived && (
                <div className={classes.actionButtons}>
                  <div className={isBusinessCardAllowed ? classes.denied : ''}>
                    <Typography
                      variant={'button'}
                      className={
                        isBusinessCardAllowed || isAllowDisabled
                          ? `${classes.buttonItem} ${classes.denied}`
                          : classes.buttonItem
                      }
                      aria-controls='allow-button'
                      onClick={allowBusinessCard}
                    >
                      {isBusinessCardAllowed
                        ? t('businessCardsPage.requestCard.message.allowed')
                        : t('commonButtons.allow')}
                    </Typography>
                  </div>
                  <div className={isBusinessCardAllowed ? classes.denied : ''}>
                    <Typography
                      variant={'button'}
                      className={
                        isAllowDisabled
                          ? `${classes.buttonItem} ${classes.denied}`
                          : classes.buttonItem
                      }
                      aria-controls='deny-button'
                      onClick={denyBusinessCard}
                    >
                      {isAllowDisabled
                        ? t('businessCardsPage.requestCard.message.denied')
                        : t('commonButtons.deny')}
                    </Typography>
                  </div>
                </div>
              )}
              {!isReceived &&
                !isBusinessCardApproved &&
                (isDenied ? (
                  <Typography variant={'body2'} className={classes.cardRequestInfo}>
                    {t('businessCardsPage.requestCard.message.denied')}
                  </Typography>
                ) : (
                  <Typography variant={'body2'} className={classes.cardRequestInfo}>
                    {t('businessCardsPage.requestCard.message.requestSent')}
                  </Typography>
                ))}
              {!isReceived && isBusinessCardApproved && (
                <Typography variant={'body2'} className={classes.cardRequestInfo}>
                  {t('businessCardsPage.requestCard.message.approved')}
                </Typography>
              )}
            </div>
          )}
        </div>
        <span className={`${classes.subHeading} ${type === 'sent' && classes.subHeadingSent}`}>
          <span> {subtitle} </span>
          {formattedTime}
        </span>
      </span>
    </span>
  )
}
