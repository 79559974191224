import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Slide } from '@material-ui/core'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import FaqItemComponent, { FaqModel } from '../../Components/Help/FaqItemComponent'
import { HelpPages, switchHelpPage } from './helpSlice'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { eventService, FrontendTypes } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  contentWrapper: {
    height: 'inherit',
    paddingBottom: theme.spacing(4),
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  faqCategoryTitle: {
    fontFamily: 'Graphik',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: theme.spacing(2, 4),
  },
  errorMessage: {
    width: '100%',
    color: 'white',
  },
}))

export const HelpFaqContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [sortedItems, setSortedItems] = useState<any>()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))

    if (!sortedItems && currentUser) {
      eventService
        .getFaqsForEvent(FrontendTypes.VISITOR, false, currentUser?.event_code)
        .then((res: any) => {
          const actualFaqsList = res.data
          if (!actualFaqsList) {
            return
          }
          if (actualFaqsList) {
            const items = groupFaqsByCategory(actualFaqsList)
            setSortedItems(items)
          }
          return
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }, [sortedItems])

  // Filter and group Faqs based on "Category"
  const groupFaqsByCategory = (actualFaqsList: FaqModel[]) => {
    if (!actualFaqsList) {
      return
    }

    const tempFaqObj: any = {}
    const faqsListByCategory = actualFaqsList.reduce((obj, listItem) => {
      const categoryItem = listItem.category
      if (obj[categoryItem]) {
        obj[categoryItem].push(listItem)
      } else {
        obj[categoryItem] = [listItem]
      }
      return obj
    }, tempFaqObj)
    const sortedFaqsList = Object.keys(faqsListByCategory).map((key) => faqsListByCategory[key])
    sortedFaqsList.forEach((listItem) => {
      listItem.sort((a: any, b: any) => a.faq_order - b.faq_order)
    })
    return sortedFaqsList
  }

  // To Convert all caps category title into sentance format
  const charConversation = (value: string) => {
    return value
      .split(' ')
      .map((categoryTitle) => {
        return categoryTitle.charAt(0).toUpperCase() + categoryTitle.substr(1).toLowerCase()
      })
      .join(' ')
  }

  // display list of Faqs based on category
  const renderFaqs = () => {
    if (!sortedItems) {
      return
    }
    return sortedItems.map((item: any) => {
      if (!item) {
        return
      }
      return (
        <Grid key={item[0].category}>
          <Grid>
            <Typography variant={'h2'} className={classes.faqCategoryTitle}>
              {charConversation(item[0]?.category)}
            </Typography>
          </Grid>
          <Grid>{renderFaqItems(item)}</Grid>
        </Grid>
      )
    })
  }

  // display Faq Item
  const renderFaqItems = (item: any) => {
    return item.map((faq: any) => {
      return <FaqItemComponent faqItem={faq} key={faq.question}></FaqItemComponent>
    })
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  const OnGoBack = () => {
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader title={t('helpMenu.faq.title')} onGoBack={OnGoBack} onClose={OnCloseClick} />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <Grid
            className={
              !errorMessage ? `${classes.container} ${classes.contentWrapper}` : classes.container
            }
          >
            {!errorMessage && sortedItems && renderFaqs()}
            {errorMessage && (
              <Typography variant={'h6'} align={'center'} className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            )}
          </Grid>
        </Slide>
      </MenuDrawer>
    </form>
  )
}

export default HelpFaqContainer
