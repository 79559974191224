import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { conversationsService } from '../../Services/services-index'
import ChatMessageInputContainer from '../Chat/ChatMessageInputContainer'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  updateActiveRoomConversation,
  updateRoomConversations,
  updateActiveRoomParticipantsCount,
  updateIsRoomConversation,
} from './RoomChatSlice'
import { ChatMessage, ChatTimestamp, Icon, MenuDrawer, MenuHeader } from '../../Common/common-index'
import { Conversation, MessageTypes, MessageActions, User } from '../../Services/services-index'
import UserAvatar from '../UserAvatar/UserAvatar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messagesWrapper: {
      padding: theme.spacing(0, 3),
      backgroundColor: theme.palette.background.default,
      flexWrap: 'nowrap',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    emptyConversations: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
      display: 'flex',
      height: '70px',
      justifyContent: 'center',
      padding: '32px',
      width: '70px',
    },
    text: {
      width: '80%',
      marginTop: theme.spacing(2),
      color: '#AFAFAF',
    },
    header: {
      marginBottom: theme.spacing(2),
      color: '#AFAFAF',
    },
    emptyBanner: {
      height: '100%',
      color: '#AFAFAF',
    },
  })
)

const RoomChatMessagesContainer: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const roomConversations = useAppSelector((state) => state.roomChat.conversations)
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const currentConversation = useAppSelector((state) => state.chat.activeConversation)

  const [roomTitle, setRoomTitle] = useState<string>('')
  const [isRoomChat, setIsRoomConversation] = useState<boolean>(false)
  const [participantsCount, setParticipantsCount] = useState<number>(0)
  const [hasConversation, setHasConversation] = useState(false)
  const [conversation, setConversation] = useState<Conversation>({} as Conversation)
  const [enableAutoScroll, setEnableAutoScroll] = useState<boolean>(true)
  const [hasNewMessageArrived, setHasNewMessageArrived] = useState<boolean>(false)
  const [bottomButtonTitle, setBottomButtonTitle] = useState<string>('')
  const [displayMessages, setDisplayMessages] = useState<any>()
  const [hasScrolledToTheTop, setHasScrolledToTheTop] = useState<boolean>(false)
  const [chatWindow, setChatWindow] = useState<any>()

  const originalUserList = useAppSelector((state) => state.user.userList)

  const isNewDay = (previousMessageTime: string, messageTime: string) => {
    if (!previousMessageTime) {
      return true
    }

    const convertedMessageTime = new Date(parseInt(messageTime, 10))
    const convertedPreviousMessageTime = new Date(parseInt(previousMessageTime, 10))
    const isSameDay =
      convertedMessageTime.getFullYear() === convertedPreviousMessageTime.getFullYear() &&
      convertedMessageTime.getMonth() === convertedPreviousMessageTime.getMonth() &&
      convertedMessageTime.getDay() === convertedPreviousMessageTime.getDay()
    return !isSameDay
  }

  useEffect(() => {
    if (!roomConversations || Object.keys(roomConversations).length === 0) {
      return
    }

    if (roomConversations) {
      const [value] = Object.entries(roomConversations)
      if (value && value.length > 1) {
        const activeRoomConversation = { ...value[1], id: value[0] }
        setConversation(activeRoomConversation)
        dispatch(updateActiveRoomConversation(activeRoomConversation))
        const { participants, isRoomConversation, roomName } = activeRoomConversation
        if (roomName) {
          setRoomTitle(roomName)
        }
        if (!participantsCount) {
          setParticipantsCount(participants.length)
        }
        if (isRoomConversation) {
          setIsRoomConversation(true)
        }

        if (
          !activeRoomConversation?.messages?.length ||
          activeRoomConversation?.lastMessage?.conversation?.includes('temp')
        ) {
          setHasConversation(false)
        } else {
          setHasConversation(true)
        }
      }
    }
    if (participantsCount) {
      dispatch(updateActiveRoomParticipantsCount(participantsCount))
    }
  }, [roomConversations, participantsCount, roomTitle])

  useEffect(() => {
    dispatch(updateIsRoomConversation(true))
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  useEffect(() => {
    if (enableAutoScroll) {
      scrollToBottom()
    }
  }, [conversation.messages?.length])

  // Add event listener for scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [enableAutoScroll])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (enableAutoScroll) {
      scrollToBottom()
    }
  }, [conversation, displayMessages])

  useEffect(() => {
    if (conversation) {
      setDisplayMessages(renderRoomChatMessages())
    }
  }, [conversation, currentConversation])

  useEffect(() => {
    if (!enableAutoScroll) {
      !hasNewMessageArrived
        ? setBottomButtonTitle('Return to bottom')
        : setBottomButtonTitle('You have new messages')
    }
  }, [enableAutoScroll, bottomButtonTitle, hasNewMessageArrived])

  useEffect(() => {
    const conversationId = conversation.id
    if (hasScrolledToTheTop && conversation.page_state && conversationId) {
      conversationsService
        .getConversationMessages(conversationId, undefined, conversation.page_state)
        .then((res: any) => {
          if (conversation.messages) {
            const { messages, page_state } = res.data
            const update = {
              ...conversation,
              messages: [...messages, ...conversation.messages],
              page_state: page_state,
            }
            dispatch(updateRoomConversations({ [conversationId]: update }))
            chatWindow.scrollTop = 100
          }
        })
    }
  }, [hasScrolledToTheTop])

  const handleScroll = (e: any) => {
    const { clientHeight, scrollTop, scrollHeight } = e?.target
    setChatWindow(e?.target)
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setEnableAutoScroll(true)
      setHasNewMessageArrived(false)
    } else {
      scrollTop < 100 ? setHasScrolledToTheTop(true) : setHasScrolledToTheTop(false)
      setEnableAutoScroll(false)
    }
  }

  const getUserName = (from: number) => {
    const user = [...conversation.participants].filter((user) => user.id === from)
    if (user.length == 0) {
      const userdata = originalUserList[from] as User
      return userdata?.firstname + ' ' + userdata?.lastname + ' - '
    }
    return user[0] && currentUser?.id != from ? `${user[0].firstname} ${user[0].lastname} - ` : ' '
  }

  // Scroll to the bottom of user conversation messages
  const scrollToBottom = () => {
    const reference = window?.document?.getElementById('lastMessageRefId')
    if (reference) {
      reference.scrollIntoView({
        behavior: 'auto',
      })
    }
    setBottomButtonTitle('')
    setHasNewMessageArrived(false)
  }

  // On empty room conversation
  const onEmptyConversationList = () => {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'} className={classes.wrapper}>
        <Box className={classes.emptyConversations} component={'span'}>
          <Icon iconName={'roomChat'} iconSize={'38px'} />
        </Box>
        <Typography variant={'h5'} align={'center'} className={classes.header}>
          {t('roomChatPage.header')}
        </Typography>
        <Typography variant={'body2'} align={'center'} className={classes.text}>
          {t('roomChatPage.emptyConversation')}
        </Typography>
        <Typography variant={'body2'} align={'center'} className={classes.text}>
          {t('roomChatPage.subText')}
        </Typography>
      </Grid>
    )
  }

  // Display room chat conversations
  const renderRoomChatMessages = () => {
    const currentUserId = currentUser?.id
    let isInitialMessage = true
    let previousMessageTime = ''
    const { messages } = conversation
    if (messages) {
      const visibleMessages = messages.filter(
        (m) =>
          m.type === MessageTypes.text &&
          m.action !== MessageActions.start &&
          m.action !== MessageActions.delete
      )
      const length = visibleMessages.length
      return visibleMessages.map((payload, index) => {
        const { message, from, timestamp } = payload
        const isReceived = currentUserId !== from
        if (index === length - 1 && enableAutoScroll) {
          scrollToBottom()
        }
        if (index === length - 1 && !hasScrolledToTheTop) {
          setHasNewMessageArrived(true)
        }

        if (isNewDay(previousMessageTime, timestamp)) {
          const text = isInitialMessage
            ? t('chatPage.chatMessages.timestamp.conversationStart')
            : ''
          isInitialMessage = false
          previousMessageTime = timestamp

          return (
            <div key={payload.id}>
              <ChatTimestamp text={text} timestamp={new Date(parseInt(timestamp, 10))} />
              <ChatMessage
                key={payload.id}
                text={message as string}
                time={new Date(parseInt(timestamp, 10))}
                isReceived={isReceived}
                subtitle={isRoomChat ? getUserName(from) : ''}
                isRoomChat={isRoomChat}
                avatar={
                  <UserAvatar
                    userId={from as number}
                    hasUnreadMessage={false}
                    width={50}
                    height={50}
                    tooltipPlacement='bottom'
                  />
                }
              />
            </div>
          )
        } else {
          return (
            <ChatMessage
              key={payload.id}
              text={message as string}
              time={new Date(parseInt(timestamp, 10))}
              isReceived={isReceived}
              subtitle={isRoomChat ? getUserName(from) : ''}
              isRoomChat={isRoomChat}
              avatar={
                <UserAvatar
                  userId={from as number}
                  hasUnreadMessage={false}
                  width={50}
                  height={50}
                  tooltipPlacement='bottom'
                />
              }
            />
          )
        }
      })
    }

    return null
  }

  // User Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(updateIsRoomConversation(false))
    dispatch(updateIsSideMenuOpen(false))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
    dispatch(updateIsRoomConversation(false))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={<MenuHeader title={roomTitle} onGoBack={OnGoBack} onClose={OnCloseClick} />}
      drawerFooter={
        <Grid container direction={'column'} spacing={1}>
          <Grid item xs={12}>
            <ChatMessageInputContainer
              enableAutoScroll={enableAutoScroll}
              bottomButtonTitle={bottomButtonTitle}
              hasNewMessageArrived={hasNewMessageArrived}
              onBottomReturn={() => scrollToBottom()}
            />
          </Grid>
        </Grid>
      }
    >
      {!hasConversation && (
        <div className={`${classes.messagesWrapper} ${classes.emptyBanner}`}>
          {onEmptyConversationList()}
        </div>
      )}
      {hasConversation && (
        <div className={classes.messagesWrapper} style={{ color: 'white' }}>
          {conversation && displayMessages}
          <div id={'lastMessageRefId'}></div>
        </div>
      )}
    </MenuDrawer>
  )
}

export default RoomChatMessagesContainer
