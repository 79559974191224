import React, { FC } from 'react'

export const Divider: FC<{ message?: string }> = ({ message }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      margin: '24px 0',
      color: 'white',
      fontSize: '16px',
    }}
  >
    <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
    {message && <span style={{ margin: '0 16px', fontWeight: '700' }}>{message}</span>}
    {message && <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />}
  </div>
)
