import React, { FC, ReactElement } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { Icon } from '../../common-index'
import { FormatText } from '../FormatText/FormatText'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      inline: {
        pointerEvents: 'none',
        '& a': { color: 'rgba(0, 0, 0, 0.54) !important' },
        color: 'white',
      },
      container: {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        width: '100%',
        height: '100%',
      },
      unread: {
        background: '#54b8f9',
        borderRadius: '50%',
        height: 12,
        width: 12,
        marginTop: '-8px',
        marginLeft: '0px',
        zIndex: 2,
        border: '1px solid white',
      },
      avatar: {
        width: 50,
        height: 50,
      },
      avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      textContainer: {
        marginLeft: theme.spacing(2),
      },
      closeWrapper: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '50%',
        boxShadow: '0px 0px 25px #00000029',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: '24px',
        width: '24px',
        '& button[class*="MuiButtonBase-root"]': {
          '&:hover': { backgroundColor: 'transparent' },
        },
      },
      micWrapper: {
        alignSelf: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        width: '48px',
        '& button[class*="MuiButtonBase-root"]': {
          '&:hover': { backgroundColor: 'transparent' },
        },
      },
      micButton: {
        cursor: 'pointer',
        '&>svg:nth-of-type(1)': { display: 'none' },
        '&:hover>svg:nth-of-type(2)': { display: 'none' },
        '&:hover>svg:nth-of-type(1)': { display: 'flex' },
      },
      endIcon: {
        '& svg': {
          height: '10px !important',
          width: '10px !important',
        },
      },
    }),
  { name: 'ChatMenuItem' }
)

export interface ChatMenuItemProps {
  id: string
  nid?: number | undefined
  title: string
  avatar: ReactElement
  description?: string
  showMic?: boolean
  isMuted?: boolean
  onClick?: () => void
  onClose?: () => void
  canMuteIndividualUsers?: boolean
  onMuteUser?: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    // eslint-disable-next-line no-unused-vars
    nid: number | undefined,
    // eslint-disable-next-line no-unused-vars
    isMuted: boolean | undefined
  ) => void
}

export const ChatMenuItem: FC<ChatMenuItemProps> = ({
  id,
  title,
  showMic,
  isMuted,
  description,
  avatar,
  onClick,
  onClose,
  nid,
  canMuteIndividualUsers,
  onMuteUser,
}) => {
  const classes = useStyles()

  return (
    <ListItem
      id={id}
      alignItems='flex-start'
      className={classes.container}
      onClick={onClick}
      button
    >
      <ListItemAvatar className={classes.avatarContainer}>{avatar}</ListItemAvatar>
      <ListItemText
        className={classes.textContainer}
        primary={title}
        primaryTypographyProps={{ variant: 'h6' }}
        secondary={
          <>
            {description && (
              <div className={classes.inline}>
                <FormatText text={description} />
              </div>
            )}
          </>
        }
      />
      {onClose && (
        <div className={classes.closeWrapper}>
          <Icon
            iconName={'x'}
            title={'Remove'}
            iconButtonProps={{
              'aria-label': 'close',
              classes: {
                root: classes.endIcon,
              },
            }}
            onClick={onClose}
          />
        </div>
      )}
      {showMic &&
        (!canMuteIndividualUsers || isMuted ? (
          <div className={classes.micWrapper}>
            <Icon
              iconName={!isMuted ? 'unmuteUserMicrophone' : 'muteUserMicrophone'}
              title={!isMuted ? 'unmuteUserMicrophone' : 'muteUserMicrophone'}
              iconButtonProps={{
                'aria-label': !isMuted ? 'unmuteUserMicrophone' : 'muteUserMicrophone',
                classes: {
                  root: classes.endIcon,
                },
              }}
            />
          </div>
        ) : (
          // Possibility to mute unmuted users
          <div
            className={`${classes.micButton} ${classes.micWrapper}`}
            onClick={(e) => onMuteUser && onMuteUser(e, nid, isMuted)}
          >
            <Icon
              id={`mic-unmuted-${nid}`}
              iconName={'microphoneHover'}
              title={'Mute Microphone'}
              iconSize='63px'
            />
            <Icon
              id={`mic-unmuted-${nid}`}
              iconName={'microphone'}
              title={'Mute Microphone'}
              iconSize='63px'
            />
          </div>
        ))}
    </ListItem>
  )
}
