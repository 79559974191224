import React, { FC, ReactElement } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import { Icon } from '../../common-index'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBar: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      width: 400,
      display: 'flex',
      position: 'relative',
      backgroundcolor: 'white',
    },
    toolbar: {
      alignItems: 'center',
      color: 'white',
      backgroundColor: 'theme.palette.background.paper',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: '80px',
      padding: '0 12px',
    },
    mainMenu: {
      position: 'relative',
      left: '45%',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
    },
    closeIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    backIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    backIconHidden: {
      display: 'flex',
      justifyContent: 'flex-start',
      visibility: 'hidden',
      width: '46px',
    },
    avatarWrapper: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%',
      bottom: '-40%',
    },
    divider: {
      margin: '0 24px',
    },
    fullDivider: {
      margin: '0',
    },
  })
)

export interface MenuHeaderProps {
  title: string
  isMainMenu?: boolean
  hasDivider?: boolean
  fullDivider?: boolean
  hasActionButtons?: boolean
  avatar?: ReactElement | null
  id?: string
  onGoBack?: () => void
  onClose?: () => void
}

export const MenuHeader: FC<MenuHeaderProps> = ({
  hasActionButtons = true,
  title,
  isMainMenu,
  hasDivider,
  fullDivider,
  onGoBack,
  onClose,
  avatar,
  id = 'menu-header',
}) => {
  const classes = useStyles()

  return (
    <AppBar position={'relative'} color={'transparent'} className={classes.menuBar} elevation={0}>
      <div className={classes.toolbar}>
        {onGoBack ? (
          <>
            {hasActionButtons && (
              <Icon
                id={id + '-back'}
                iconName={'back'}
                iconButtonProps={{
                  'aria-label': 'backwards',
                  classes: {
                    root: classes.backIcon,
                  },
                }}
                title={'Back'}
                onClick={onGoBack}
              />
            )}
          </>
        ) : (
          !isMainMenu && <div className={classes.backIconHidden}></div>
        )}
        <div className={isMainMenu ? `${classes.title} ${classes.mainMenu}` : classes.title}>
          <Typography variant={'subtitle2'} id={'menu-header-' + id}>
            {title}
          </Typography>
        </div>
        {onClose ? (
          <>
            {hasActionButtons && (
              <Icon
                id={id + '-close'}
                iconName={'x'}
                iconButtonProps={{
                  'aria-label': 'close',
                  classes: {
                    root: classes.closeIcon,
                  },
                }}
                title={'Close'}
                onClick={onClose}
              />
            )}
          </>
        ) : (
          !isMainMenu && <div className={classes.backIconHidden}></div>
        )}
      </div>
      {hasDivider && <Divider className={fullDivider ? classes.fullDivider : classes.divider} />}
      {avatar && <div className={classes.avatarWrapper}>{avatar}</div>}
    </AppBar>
  )
}
