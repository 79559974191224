import React, { FC, useState } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Icon } from '../../../Common/common-index'

type Props = Omit<TextFieldProps, 'onChange'> & {
  mandatory?: boolean
  title?: string
  placeholder?: string
  value?: string
  autoComplete?: string
  isPasswordField?: boolean
  error?: boolean
  errorMessage?: string
  id?: string
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      color: 'white',
      alignItems: 'flex-start',
      display: 'inline-grid',
      flexDirection: 'column',
      marginBottom: '24px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    label: {
      color: 'white',
      textAlign: 'left',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0),
        width: '400px',
      },
    },
    input: {
      color: 'white',
      fontSize: '16px',
      fontFamily: 'Graphik-Regular',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    visibilityIcon: {
      position: 'absolute',
      right: '-2%',
      top: '50%',
      transform: 'translateY(-50%)',
      '& svg': {
        color: '#212121',
        height: '15px !important',
        width: '15px !important',
      },
    },
    errorStatus: {
      fontSize: '12px',
      color: theme.palette.error.main,
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: '-21px',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        padding: 0,
      },
    },
    errorLable: {
      color: theme.palette.error.main,
    },
  })
)

const FormTextField: FC<Props> = ({
  mandatory,
  title,
  placeholder,
  value,
  autoComplete,
  isPasswordField,
  error,
  errorMessage,
  id,
  onChange,
}) => {
  const classes = useStyles()
  const [isPasswordVisible, setPassworVisible] = useState(false)

  const validateType = () => {
    if (isPasswordField) {
      return isPasswordVisible ? 'text' : 'password'
    }
    return 'text'
  }

  return (
    <div className={classes.fieldItem}>
      <FormControl>
        <Typography
          className={error ? `${classes.label} ${classes.errorLable}` : classes.label}
          variant='body1'
        >
          {title}
        </Typography>
        <TextField
          placeholder={placeholder}
          inputProps={{
            'aria-label': `${placeholder}`,
            color: 'white',
            maxLength: 2000,
          }}
          value={value}
          id={id}
          onChange={(e) => onChange(e.target.value)}
          autoComplete={autoComplete}
          error={error}
          type={validateType()}
          required={!!mandatory}
          InputProps={{
            classes: {
              input: error && value ? `${classes.input} ${classes.errorLable}` : classes.input,
            },
            endAdornment: isPasswordField && (
              <InputAdornment position='end'>
                <Icon
                  title={isPasswordVisible ? 'Hide password' : 'Show password'}
                  iconName={isPasswordVisible ? 'visibilityOff' : 'visiblePassword'}
                  onClick={() => setPassworVisible((visible) => !visible)}
                  iconButtonProps={{
                    'aria-label': 'password visibility',
                    classes: {
                      root: classes.visibilityIcon,
                    },
                  }}
                  svgIconProps={{
                    color: 'primary',
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {error && (
          <Typography variant='body2' className={classes.errorStatus}>
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </div>
  )
}

export default FormTextField
