import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { FormGroup, TextField, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { updateSettings } from '../../Containers/ProfileSettings/profileSettingsSlice'
import { userService } from '../../Services/services-index'
import { ShadowedButton } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
  },
  label: {
    color: 'white',
  },
  title: {
    margin: theme.spacing(5, 0),
    fontSize: '15px',
  },
  formGroup: {
    '& div': {
      marginBottom: '15px',
      '&[class*="MuiInput-underline"]:after': {
        borderBottom: theme.palette.grey.A100,
      },
      '& input': { fontFamily: 'Graphik-Regular', fontSize: '15px' },
      color: 'white',
    },
    '& label': {
      fontFamily: 'Graphik-Regular',
      fontSize: '15px',
      '&[class*="Mui-focused"]': {
        color: 'white',
        fontSize: '15px',
        opacity: '0.6',
      },
      '& &[class*="Mui-filled"]': { fontSize: '15px' },
    },
  },
  button: {
    marginTop: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  errorStatus: {
    fontSize: '15px',
    color: '#b71c1c',
    paddingBottom: theme.spacing(3),
  },
  successStatus: {
    color: 'white',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
}))

export const ProfileInformationComponent: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const activeUserDetails = useAppSelector((state) => state.profileSettings.userProfile)

  const [modifiedUser, setModifiedUser] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [validFields, setValidFields] = useState<{
    name?: boolean | string
    lastName?: boolean | string
    email?: boolean | string
    jobTitle?: boolean | string
    company?: boolean | string
    phoneNumber?: boolean | string
    website?: boolean | string
    linkedin?: boolean | string
  }>({
    name: undefined,
    lastName: undefined,
    email: undefined,
    jobTitle: undefined,
    company: undefined,
    phoneNumber: undefined,
    website: undefined,
    linkedin: undefined,
  })

  useEffect(() => {
    if (!modifiedUser && activeUserDetails) {
      setModifiedUser({
        firstname: activeUserDetails['firstname'] ? activeUserDetails['firstname'] : '',
        lastname: activeUserDetails['lastname'] ? activeUserDetails['lastname'] : '',
        job_title: activeUserDetails['job_title'] ? activeUserDetails['job_title'] : '',
        company: activeUserDetails['company'] ? activeUserDetails['company'] : '',
        phone_number: activeUserDetails['phone_number'] ? activeUserDetails['phone_number'] : '',
        email: activeUserDetails['email'] ? activeUserDetails['email'] : '',
        webpage: activeUserDetails['webpage'] ? activeUserDetails['webpage'] : '',
        linkedin: activeUserDetails['linkedin'] ? activeUserDetails['linkedin'] : '',
      })
    }
  }, [modifiedUser, activeUserDetails])

  useEffect(() => {}, [validFields, modifiedUser])

  useEffect(() => {
    if (Object.values(validFields).some((field) => field)) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [modifiedUser, validFields])

  const updateUser = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    if (modifiedUser) {
      const user = modifiedUser
      switch (name) {
        case 'Name': {
          setModifiedUser({ ...modifiedUser, firstname: event.target.value })
          let errorMessage
          if (!event.target.value) {
            errorMessage = 'Name is invalid'
          } else if (event.target.value.match(/^[\p{L}\p{N}\-\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Name contains forbidden characters'
          }
          setValidFields({ ...validFields, name: errorMessage })
          break
        }
        case 'Last Name': {
          setModifiedUser({ ...modifiedUser, lastname: event.target.value })
          let errorMessage
          if (!event.target.value) {
            errorMessage = 'Last name is invalid'
          } else if (event.target.value.match(/^[\p{L}\p{N}\-\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Last name contains forbidden characters'
          }
          setValidFields({ ...validFields, lastName: errorMessage })
          break
        }
        case 'Job Title': {
          setModifiedUser({ ...modifiedUser, job_title: event.target.value })
          let errorMessage
          if (!event.target.value || event.target.value.match(/^[\p{L}\p{N}\-&\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Job title contains forbidden characters'
          }
          setValidFields({ ...validFields, jobTitle: errorMessage })
          break
        }
        case 'Company': {
          setModifiedUser({ ...modifiedUser, company: event.target.value })
          let errorMessage
          if (!event.target.value || event.target.value.match(/^[\p{L}\p{N}\-&\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Company contains forbidden characters'
          }
          setValidFields({ ...validFields, company: errorMessage })
          break
        }
        case 'Phone': {
          setModifiedUser({ ...modifiedUser, phone_number: event.target.value })
          let errorMessage
          if (!event.target.value || event.target.value.match(/^[\p{L}\p{N}\-\+\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Phone number contains forbidden characters'
          }
          setValidFields({ ...validFields, phoneNumber: errorMessage })
          break
        }
        case 'Email': {
          setModifiedUser({ ...modifiedUser, email: event.target.value })
          let errorMessage
          if (!event.target.value || !event.target.checkValidity()) {
            errorMessage = 'Email is invalid'
          } else if (event.target.value.match(/^[\p{L}\p{N}\-_@&\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Email contains forbidden characters'
          }
          setValidFields({ ...validFields, email: errorMessage })
          break
        }
        case 'Webpage': {
          setModifiedUser({ ...modifiedUser, webpage: event.target.value })
          let errorMessage
          if (event.target.value) {
            const webpageReg = new RegExp(
              /^([-a-zA-Z0-9@:&.\/_~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:&._~#\/=]*))*$/
            )
            const isWebpageValid = webpageReg.test(event.target.value)
            if (!isWebpageValid) {
              errorMessage = 'Webpage is invalid or contains forbidden characters'
            } else {
              errorMessage = false
            }
          } else {
            errorMessage = false
          }
          setValidFields({ ...validFields, website: errorMessage })
          break
        }
        case 'Linkedin': {
          setModifiedUser({ ...modifiedUser, linkedin: event.target.value })
          let errorMessage
          if (!event.target.value || event.target.value.match(/^[\p{L}\p{N}\-@:\/\s\.]+$/u)) {
            errorMessage = false
          } else {
            errorMessage = 'Linkedin contains forbidden characters'
          }
          setValidFields({ ...validFields, linkedin: errorMessage })
          break
        }
        default: {
          return user
        }
      }
    }
  }

  const modifyUser = () => {
    if (modifiedUser && activeUserDetails?.id) {
      userService
        .editUser(modifiedUser, activeUserDetails?.id)
        .then((res) => {
          if (res?.status === 200) {
            dispatch(updateSettings(res.data?.user))
            setSuccessMessage(t('profileSettingsPage.informationPage.successInfo'))
            setErrorMessage('')

            // Turn of success message
            setTimeout(() => {
              setSuccessMessage('')
            }, 3500)
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
          setSuccessMessage('')
        })
    }
  }

  const checkFieldValidity = (field: string): boolean => {
    if (validFields[`${field}`] !== undefined) {
      return validFields[`${field}`]
    }
    return false
  }

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <div className={classes.paperContainer} style={{ color: 'white' }}>
        <Typography
          variant={'body2'}
          data-testid='title'
          className={classes.title}
          style={{ color: 'white' }}
        >
          {t('profileSettingsPage.informationPage.info')}
        </Typography>
        <FormGroup className={classes.formGroup}>
          <TextField
            id='profile-info-first-name'
            label='Name'
            InputLabelProps={{
              className: classes.label,
            }}
            required
            error={checkFieldValidity('name')}
            helperText={validFields['name']}
            variant='standard'
            value={modifiedUser?.firstname}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => updateUser(e, 'Name')}
          />
          <TextField
            id='profile-info-last-name'
            label='Last Name'
            InputLabelProps={{
              className: classes.label,
            }}
            required
            error={checkFieldValidity('lastName')}
            helperText={validFields['lastName']}
            variant='standard'
            value={modifiedUser?.lastname}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => updateUser(e, 'Last Name')}
          />
          <TextField
            id='profile-info-title'
            label='Job Title'
            InputLabelProps={{
              className: classes.label,
            }}
            variant='standard'
            error={checkFieldValidity('jobTitle')}
            helperText={validFields['jobTitle']}
            value={modifiedUser?.job_title}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => updateUser(e, 'Job Title')}
          />
          <TextField
            id='profile-info-company'
            label='Company'
            InputLabelProps={{
              className: classes.label,
            }}
            variant='standard'
            error={checkFieldValidity('company')}
            helperText={validFields['company']}
            value={modifiedUser?.company}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => updateUser(e, 'Company')}
          />
          <TextField
            id='profile-info-phone'
            type='number'
            label='Phone'
            InputLabelProps={{
              className: classes.label,
            }}
            variant='standard'
            error={checkFieldValidity('phoneNumber')}
            helperText={validFields['phoneNumber']}
            value={modifiedUser?.phone_number}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => updateUser(e, 'Phone')}
          />
          <TextField
            id='profile-info-email'
            type='email'
            InputLabelProps={{
              className: classes.label,
            }}
            required
            error={checkFieldValidity('email')}
            helperText={validFields['email']}
            inputProps={{
              maxLength: 100,
            }}
            label='Email'
            variant='standard'
            value={modifiedUser?.email}
            onChange={(e) => updateUser(e, 'Email')}
          />
          <TextField
            id='profile-info-url'
            type='url'
            label='Webpage'
            InputLabelProps={{
              className: classes.label,
            }}
            error={checkFieldValidity('website')}
            helperText={checkFieldValidity('website')}
            inputProps={{
              maxLength: 100,
            }}
            variant='standard'
            value={modifiedUser?.webpage}
            onChange={(e) => updateUser(e, 'Webpage')}
          />
          <TextField
            id='profile-info-LinkedIn'
            label='Linkedin'
            InputLabelProps={{
              className: classes.label,
            }}
            error={checkFieldValidity('linkedin')}
            helperText={checkFieldValidity('linkedin')}
            inputProps={{
              maxLength: 100,
            }}
            variant='standard'
            value={modifiedUser?.linkedin}
            onChange={(e) => updateUser(e, 'Linkedin')}
          />
        </FormGroup>
        {(errorMessage || successMessage) && (
          <Typography
            variant='body2'
            className={
              errorMessage ? classes.errorStatus : `${classes.errorStatus} ${classes.successStatus}`
            }
          >
            {errorMessage || successMessage}
          </Typography>
        )}
        <ShadowedButton
          id='profile-info-submit'
          text={t('profileSettingsPage.informationPage.save')}
          buttonStyle={'default'}
          wrapperClasses={classes.button}
          disabled={isDisabled}
          onClick={modifyUser}
        ></ShadowedButton>
      </div>
    </Slide>
  )
}
