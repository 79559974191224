import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { OutlinedInput, InputAdornment } from '@material-ui/core'
import { Icon } from '../../common-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchRoot: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3, 0),
      width: '100%',
      '& fieldset[class*="PrivateNotchedOutline-root"]': {
        borderColor: theme.palette.background.paper,
      },
    },
    searchWrapper: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      marginRight: theme.spacing(3),
      marginLeft: 0,
      position: 'relative',
      width: '100%',
    },
    searchInput: {
      fontFamily: 'Graphik-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
      width: '100%',
      height: '40px',
      backgroundColor: 'white',
    },
    searchFocusIcon: {
      '& path[class*="Search_svg__a"]': {
        fill: theme.palette.primary.main,
      },
    },
    defaultSearchIcon: {
      '& path[class*="Search_svg__a"]': {
        fill: theme.palette.grey[100],
      },
    },
  })
)

export interface SearchBarProps {
  placeholderText: string
  searchTextInput?: string
  hideAutoFill?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (e: any) => void
  id: string
}

export const SearchBar: FC<SearchBarProps> = ({
  placeholderText,
  searchTextInput,
  hideAutoFill,
  onChange,
  id,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.searchRoot}>
      <div className={classes.searchWrapper}>
        <OutlinedInput
          id={id}
          placeholder={placeholderText}
          notched={true}
          value={searchTextInput}
          className={classes.searchInput}
          autoComplete={hideAutoFill ? 'off' : 'on'}
          onChange={onChange}
          inputProps={{
            maxLength: 2000,
          }}
          startAdornment={
            <InputAdornment position='start'>
              <Icon
                iconName='search'
                iconSize={'16px'}
                iconButtonProps={{
                  'aria-label': 'search',
                  classes: {
                    root: searchTextInput ? classes.searchFocusIcon : classes.defaultSearchIcon,
                  },
                }}
              />
            </InputAdornment>
          }
        />
      </div>
    </div>
  )
}
