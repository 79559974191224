import React, { FC } from 'react'
// import { Icons } from './icons/Icon'

export const LoginHeader: FC = () => {
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  }

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 600,
    margin: 0,
  }

  // const closeButtonStyle = {
  //   background: 'none',
  //   border: 'none',
  //   color: '#fff',
  //   cursor: 'pointer',
  //   fontSize: '20px',
  // }

  return (
    <div style={headerStyle}>
      <h1 style={titleStyle}>Login</h1>
      {/* <button style={closeButtonStyle}>
        <Icons.Close />
      </button> */}
    </div>
  )
}
