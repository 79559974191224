import React, { FC, useState, useEffect } from 'react'
import { Icons } from './icons/Icon'
import { getProviders } from '../../../Services/Services/auth.service'
import { FrontendTypes } from '../../../Services/Models/app.model'
import { Divider } from './Divider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import './login-form.css'
import { authService, localStorageService } from '../../../Services/services-index'
import './social-login-button.css'

export const LoginForm: FC<{ onNewBackgroundImage?: (domain: string) => void }> = ({
  onNewBackgroundImage,
}) => {
  const { domain, realityCode } = useParams<{ domain: string; realityCode: string }>()
  console.log(domain, realityCode)

  const history = useHistory()
  const location: any = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }

  const OAuth2URL = authService.servicePrefix + '/oauth2'

  const [formData, setFormData] = useState({
    email: 'james.green@test.com',
    password: 'testDT@2020',
    domain: domain || '',
    consentPrivacy: false,
    consentCookies: false,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [isCheckingCode, setIsCheckingCode] = useState(false)
  const [isValidDomain, setIsValidDomain] = useState<boolean | null>(null)
  const [providers, setProviders] = useState<string[]>([])
  const [isAdminLogin, setIsAdminLogin] = useState(false)

  const [isEmailPasswordShown, setIsEmailPasswordShown] = useState(true)

  const hideLogin =
    isValidDomain === false ||
    isValidDomain === null ||
    formData.consentCookies === false ||
    formData.consentPrivacy === false

  // Simulate backend call
  const checkRealityCode = async (code: string): Promise<boolean> => {
    return new Promise(async (resolve) => {
      try {
        const providers = await getProviders(FrontendTypes.VISITOR, code)
        setIsEmailPasswordShown(providers.data.user_pass_login === 'yes')
        setProviders(providers.data.providers.map((provider: any) => provider.name))
        resolve(true)
      } catch (err) {
        console.error('Error checking Reality Code', err)
        resolve(false)
      }
    })
  }

  const onOauthClick = async (provider: string) => {
    console.log('provider', provider)
    localStorage.setItem('eventCode', formData.domain)
    localStorage.setItem('provider', provider)
    localStorage.setItem('NEW_LOGIN_SYSTEM', 'true')
    window.location.href = `${OAuth2URL}?frontend=${FrontendTypes.VISITOR}&provider=${provider}`
  }

  // Debounced reality code check
  useEffect(() => {
    if (!formData.domain) {
      setIsValidDomain(null)
      return
    }

    setIsCheckingCode(true)
    setProviders([])

    const timeoutId = setTimeout(async () => {
      try {
        const isValid = await checkRealityCode(formData.domain)
        onNewBackgroundImage?.(formData.domain)
        setIsValidDomain(isValid)
      } catch (err) {
        setIsValidDomain(false)
      } finally {
        setIsCheckingCode(false)
      }
    }, 500) // 500ms debounce

    return () => clearTimeout(timeoutId)
  }, [formData.domain])

  // Update handleSubmit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsCheckingCode(true)

    try {
      const authResult = await authService.signIn({
        email: formData.email,
        password: formData.password,
        event_code: formData.domain,
        frontend: FrontendTypes.VISITOR,
        force_login: true,
      })
      const { user_data: userData } = (authResult as any).data

      localStorageService.setLocalStorageItemValue('currentUser', JSON.stringify(userData))
      localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
      localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
      localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
      localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))

      history.replace(from)
      history.push('/lobby')

      // Continue with form submission
      console.log('Form submitted successfully')
    } catch (err) {
      console.log('err', err)
      setFormError('Error checking Reality Code' + err)
    } finally {
      setIsCheckingCode(false)
    }
  }

  const onAdminClick = () => {
    if (isAdminLogin) {
      setIsAdminLogin(false)
      checkRealityCode(formData.domain)
      setProviders([])
      formData.consentCookies = false
      formData.consentPrivacy = false
    } else {
      setIsAdminLogin(true)
      setIsValidDomain(true)
      setProviders(['accenture'])
      formData.consentCookies = true
      formData.consentPrivacy = true
    }
  }

  const notAllowedStyle: React.CSSProperties = hideLogin
    ? {
        cursor: 'not-allowed',
        filter: 'blur(5px)',
      }
    : {}

  const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    color: '#ccc',
    fontSize: '14px',
    boxSizing: 'border-box',
  }

  const checkboxContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    fontSize: '14px',
    color: '#ccc',
    boxSizing: 'border-box',
  }

  const submitButtonStyle: React.CSSProperties = {
    width: '100%',
    padding: '14px',
    backgroundColor: '#9333ea',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '16px',
    boxSizing: 'border-box',
  }

  const errorStyle: React.CSSProperties = {
    backgroundColor: 'rgba(220, 38, 38, 0.1)',
    color: '#ef4444',
    padding: '12px',
    borderRadius: '8px',
    marginBottom: '16px',
    fontSize: '14px',
    boxSizing: 'border-box',
  }

  // Add loading indicator style
  // const loadingIndicatorStyle: React.CSSProperties = {
  //   position: 'absolute',
  //   right: '12px',
  //   top: '38px',
  //   color: '#9333ea',
  // }

  // Style for the status icon
  const statusIconStyle: React.CSSProperties = {
    position: 'absolute',
    right: '10px',
    top: '35px',
    display: 'flex',
    alignItems: 'center',
    width: '20px',
    height: '20px',
  }

  console.log(isCheckingCode)

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ position: 'relative' }}>
          <label style={labelStyle}>Domain</label>
          <input
            type='text'
            value={formData.domain}
            onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
            className='login-form-input'
            disabled={domain !== undefined && isValidDomain === true}
            required
          />
          <span style={statusIconStyle}>
            {isCheckingCode && <Icons.Spinner />}
            {!isCheckingCode && isValidDomain === true && <Icons.CheckCircle />}
            {!isCheckingCode && isValidDomain === false && <Icons.XCircle />}
          </span>
        </div>
        <div style={checkboxContainerStyle}>
          <input
            type='checkbox'
            checked={formData.consentPrivacy}
            onChange={(e) => setFormData({ ...formData, consentPrivacy: e.target.checked })}
            style={{ marginRight: '8px' }}
          />
          <span>I consent to the Accenture Privacy Policy</span>
        </div>
        <div style={checkboxContainerStyle}>
          <input
            type='checkbox'
            checked={formData.consentCookies}
            onChange={(e) => setFormData({ ...formData, consentCookies: e.target.checked })}
            style={{ marginRight: '8px' }}
          />
          <span>
            I consent with the use of local storage technology (similar to functional cookies)
          </span>
        </div>

        <div>
          <Divider />
          {isEmailPasswordShown && (
            <div style={notAllowedStyle}>
              <div>
                <label style={labelStyle}>Email</label>
                <input
                  type='email'
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className='login-form-input'
                  placeholder='name.surname@mail.com'
                  required
                />
              </div>

              <div style={{ position: 'relative' }}>
                <label style={labelStyle}>Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className='login-form-input'
                  required
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '12px',
                    top: '38px',
                    background: 'none',
                    border: 'none',
                    color: '#ccc',
                    cursor: 'pointer',
                  }}
                >
                  {showPassword ? <Icons.EyeCrossed /> : <Icons.Eye />}
                </button>
              </div>

              {formError && (
                <div style={errorStyle}>
                  <strong>❗ {formError}</strong>
                  <p style={{ margin: '4px 0 0' }}>
                    Verify that the Reality Code is accurate and has not expired. If so, request a
                    new one.
                  </p>
                </div>
              )}

              <button type='submit' style={submitButtonStyle}>
                {isAdminLogin === true ? 'Access Dashboard' : 'Login'}
              </button>
            </div>
          )}

          <Divider message='Or login with' />

          <div className='social-login-container' style={notAllowedStyle}>
            {(providers.includes('google') || hideLogin) && (
              <button
                key={'google'}
                className='social-login-button'
                onClick={() => onOauthClick('google')}
              >
                <Icons.Google />
                Google
              </button>
            )}
            {(providers.includes('accenture') || hideLogin) && (
              <button
                key={'accenture'}
                className='social-login-button'
                onClick={() => onOauthClick('accenture')}
              >
                <Icons.Accenture />
                Accenture
              </button>
            )}
            {(providers.includes('linkedin') || hideLogin) && (
              <button
                key={'linkedin'}
                className='social-login-button'
                disabled={!providers.includes('linkedin')}
                onClick={() => onOauthClick('linkedin')}
              >
                <Icons.LinkedIn />
                LinkedIn
              </button>
            )}
            {(providers.includes('facebook') || hideLogin) && (
              <button
                key={'facebook'}
                className='social-login-button'
                disabled={!providers.includes('facebook')}
                onClick={() => onOauthClick('facebook')}
              >
                <Icons.Facebook />
                Facebook
              </button>
            )}
          </div>
          <div
            style={{
              marginTop: '16px',
              fontSize: '12px',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
            }}
            onClick={onAdminClick}
          >
            <p style={{ textDecoration: 'underline' }}>
              {isAdminLogin ? 'Login as visitor' : 'Login as administrator'}
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
