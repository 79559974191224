import React, { FC } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { ShadowedButton } from '../Buttons/ShadowedButton'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      alignItems: 'center',
      padding: 20,
      borderRadius: '8px',
      overflowX: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    dialogTitle: {
      marginTop: '70px',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Graphik-Semibold',
      textAlign: 'center',
      color: 'white',
    },
    dialogContent: {
      fontFamily: 'Graphik-Regular',
      fontSize: '21px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '40px',
      overflowX: 'hidden',
      color: 'white',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginBottom: '40px',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    singleButtonMode: {
      flexDirection: 'column',
    },
    surveyWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonWrapper: {
      width: '65%',
      padding: '16px',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      '& button[class*="makeStyles-buttonDark"]': {
        marginLeft: 0,
      },
    },
    surveyMode: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    standardMode: {
      display: 'contents',
    },
    disableAction: {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: '0.5',
    },
    errorStatus: {
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: '-21px',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        margin: '16px',
        justifyContent: 'center',
      },
    },
    successStatus: {
      color: '#460073',
      fontWeight: 'bold',
    },
  })
)

export interface ConfirmationDialogProps {
  open: boolean
  titleText: string
  contentText?: string
  cancelText?: string
  confirmText: string
  errorMessage?: string
  successMessage?: string
  disableAction?: boolean
  showSurveyMode?: boolean
  onConfirm: Function
  onCancel?: () => void
  onRejection?: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open = false,
  onCancel = () => {},
  onRejection,
  onConfirm,
  titleText,
  contentText,
  cancelText,
  confirmText,
  errorMessage,
  successMessage,
  disableAction,
  showSurveyMode,
  ...other
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={open}
      {...other}
    >
      <DialogTitle
        id='confirmation-dialog-title'
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <Typography variant={'h3'}>{t(titleText)}</Typography>
      </DialogTitle>
      {contentText && (
        <DialogContent className={classes.dialogContent}>{t(contentText)}</DialogContent>
      )}
      <DialogActions
        classes={{
          root: showSurveyMode
            ? `${classes.dialogActions} ${classes.surveyWrapper}`
            : confirmText && cancelText
            ? classes.dialogActions
            : `${classes.dialogActions} ${classes.singleButtonMode}`,
        }}
        disableSpacing
      >
        <div className={showSurveyMode ? classes.surveyMode : classes.standardMode}>
          {confirmText && (
            <ShadowedButton
              id='confirmation-dialog-confirm'
              text={t(confirmText)}
              buttonStyle={'default'}
              autoFocus={true}
              disabled={disableAction}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={handleConfirm}
            ></ShadowedButton>
          )}
          {showSurveyMode && (
            <ShadowedButton
              id='confirmation-dialog-reject'
              text={t('authPages.logoutDialog.noText')}
              disabled={disableAction}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={onRejection}
            ></ShadowedButton>
          )}
        </div>
        {cancelText && (
          <ShadowedButton
            id='confirmatin-dialog-cancel'
            text={t(cancelText)}
            buttonStyle={'dark'}
            hasDialogButtonShadow={true}
            wrapperClasses={classes.buttonWrapper}
            onClick={handleCancel}
          ></ShadowedButton>
        )}
      </DialogActions>

      {(errorMessage || successMessage) && (
        <DialogContent
          className={
            errorMessage ? classes.errorStatus : `${classes.errorStatus} ${classes.successStatus}`
          }
        >
          {errorMessage || successMessage}
        </DialogContent>
      )}
    </Dialog>
  )
}
