import { Card, CardActions, CardContent, Modal } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC, useMemo } from 'react'
import { Icon } from '../Icon/Icon'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'

const useStyles = makeStyles((theme: Theme) => {
  const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'caption']
  const themeTags = {}
  tags.forEach((tag) => {
    themeTags[`& ${tag}`] = { ...theme.typography[tag] }
  })
  return {
    root: themeTags,
    modal: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    mediaBlock: {
      height: '100%',
      width: '56.25%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: '50%',
      objectFit: 'cover',
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      pointerEvents: 'none',
    },
    dialogExitContainer: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogExitContainerStatic: {
      position: 'static',
      padding: '20px',
    },
    cardNoMedia: {
      width: '60%',
      height: '40%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    cardHasMedia: {
      width: '60%',
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    cardActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    iconWrapper: {
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    visibilityIcon: {
      '& svg': {
        color: 'white',
        height: '25px !important',
        width: '25px !important',
      },
    },
    icon: {
      '& svg': {
        color: 'white',
        height: '20px !important',
        width: '20px !important',
      },
    },
    cardHtml: {
      overflow: 'auto',
      textAlign: 'center',
    },
    popupDialogButton: {
      margin: '19px 20px 30px 20px',
      width: '220px',
      borderColor: theme.palette.primary.main,
      fontFamily: 'Graphik-Semibold',
      alignItems: 'center',
      color: 'white',
      fontSize: '18px',
      lineHeight: 1.4,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '6px 10px #00000020',
      height: '46px',
      textTransform: 'none',
      transition: 'all 0.4s ease-in-out',
      zIndex: 1,
      '&:hover': {
        boxShadow: '11px 13px #00000020',
        backgroundColor: theme.palette.primary.main,
        transform: 'translate(-5px, -3px)',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    paragraph: {
      fontSize: '21px',
      lineHeight: 1.4,
      fontFamily: 'Graphik-Regular',
    },
  }
})

export interface PopupDialogProps {
  open: boolean | undefined
  html: any
  mediaSrcOrPath: string | undefined
  mediaType: string | undefined
  optOut: string | undefined
  image?: any
  onDisablePopup: () => void
  onClose: () => void
}

export const PopupDialog: FC<PopupDialogProps> = ({
  open = false,
  html,
  mediaSrcOrPath,
  mediaType,
  optOut,
  image,
  onDisablePopup,
  onClose,
}) => {
  const classes = useStyles()
  const parsedHtml = useMemo(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const buttons = doc.getElementsByTagName('button')
    const paragraphs = doc.getElementsByTagName('p')
    Array.from(paragraphs).forEach((paragraph) => {
      paragraph.classList.add(classes.paragraph)
    })
    Array.from(buttons).forEach((button) => {
      button.classList.add(classes.popupDialogButton)
    })
    return doc.getElementsByTagName('body').item(0)?.innerHTML ?? ''
  }, [html])
  const sanitizedHtml = DOMPurify.sanitize(parsedHtml)

  return (
    <>
      <Modal id='popup-dialog-modal' className={classes.modal} open={open} onClose={onClose}>
        <Card className={mediaSrcOrPath ? classes.cardHasMedia : classes.cardNoMedia}>
          <CardActions className={classes.cardActions}>
            {optOut === 'true' && (
              <div className={classes.iconWrapper}>
                <Icon
                  id='popup-dialog-disable'
                  iconName={'visibilityOff'}
                  title={'Disable OptOut'}
                  iconSize={'25px'}
                  iconButtonProps={{
                    'aria-label': 'OptOut',
                    classes: {
                      root: classes.visibilityIcon,
                    },
                  }}
                  onClick={onDisablePopup}
                />
              </div>
            )}

            <div className={classes.iconWrapper}>
              <Icon
                id='popup-dialog-close'
                iconName={'x'}
                title={'Close'}
                iconSize={'20px'}
                iconButtonProps={{
                  'aria-label': 'close',
                  classes: {
                    root: classes.icon,
                  },
                }}
                onClick={onClose}
              />
            </div>
          </CardActions>
          {mediaSrcOrPath && mediaType === 'video' && (
            <video
              className={classes.video}
              autoPlay
              crossOrigin='anonymous'
              src={mediaSrcOrPath}
            ></video>
          )}
          {mediaSrcOrPath && mediaType === 'image' && image && (
            <img className={classes.image} src={image} />
          )}
          <CardContent className={classes.cardHtml}>
            <div className={classes.root}> {parse(sanitizedHtml)} </div>
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}
