import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { localStorageService } from '../../../Services/services-index'
import { authService } from '../../../Services/services-index'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import { useAppDispatch } from '../../../Reducers/hooks'

function useSearchParams() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

export const NewOAuth2Page: FC = () => {
  const searchParams = useSearchParams()
  const history = useHistory()
  const location: any = useLocation()
  const dispatch = useAppDispatch()

  const { from } = location.state || { from: { pathname: '/' } }

  const [retryToken, setRetryToken] = useState('')

  useEffect(() => {
    // const eventCode = searchParams.get('event') ?? ''
    // const accessCode = searchParams.get('access_code') ?? ''
    const callbackCode = searchParams.get('code') ?? ''

    const activeProvider = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    )

    const eventCode = localStorage.getItem('eventCode')
    const provider = localStorage.getItem('provider')

    if (!provider || !eventCode) {
      console.error('No provider or eventCode', provider, eventCode)
      return
    }

    console.log('==== eventCode ', eventCode)
    console.log('==== callbackCode ', callbackCode)
    console.log('==== activeProvider ', activeProvider)
    console.log(eventCode, callbackCode, activeProvider)

    authService
      .postOauth2('visitor', provider, eventCode, '', retryToken, callbackCode)
      .then((res: any) => {
        console.log('==== res ', res)
        if (res.data && res.data.user_data && res.data.user_data.id) {
          dispatch(updateCurrentUser(res.data.user_data))
          // checkMiniMapStateAtStart(res.data.user_data?.event_code)

          // Store values in localstore
          localStorageService.setLocalStorageItemValue(
            'currentUser',
            JSON.stringify(res.data.user_data)
          )
          localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
          localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
          localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
          localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))
          localStorageService.clearLocalStorageItemValue('eventCode')

          const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
          if (traverseRedirect) {
            localStorageService.clearLocalStorageItemValue('traverseRedirect')
            history.replace(from)
            history.push('/auth')
          } else {
            history.replace(from)
            history.push('/lobby')
          }
        }
      })
      // We have to send a POST request and receive an error response to obtain
      // user information and decide whether to prompt for an access code
      // A new retry token is received after every request
      .catch((err: any) => {
        const errorCode = err?.response?.data?.error_code
        const errorText = err?.response?.data?.message
        const _retryToken = err?.response?.data?.retry_token
        setRetryToken(_retryToken ? _retryToken : '')

        // Missing / invalid access code response
        // if (_retryToken && errorText.toLowerCase().includes('access code')) {
        //   setAccessCodeErrorMessage(errorText)
        //   setOpenDialog(true)
        //   if (accessCode) {
        //     setInvalidAccessCodeEntered(true)
        //   }
        // }
        // // Other responses, e.g. invalid event code
        // else {
        //   setErrorMessage(errorText)
        //   setEventCode('')
        //   setInvalidAccessCodeEntered(false)
        //   setOpenDialog(false)
        // }

        // User takes too long to enter access code
        if (errorText === 'Login session expired.') {
          setTimeout(() => {
            history.push('/auth/login')
          }, 4000)
        }

        if (errorCode === 401.2) {
          setTimeout(() => {
            history.push('/auth/login')
          }, 4000)
        }
      })
  }, [searchParams])

  // if (eventCode == null && ssoCode === null) {
  //   localStorageService.clearLocalStorageItemValue('eventCode')
  // } else {
  //   let storageEvent
  //   if (localStorageService.getLocalStorageItemValue('eventCode')) {
  //     storageEvent = JSON.parse(localStorageService.getLocalStorageItemValue('eventCode'))
  //   }
  //   eventCode = eventCode || storageEvent
  // }

  // if (accessCode == null && ssoCode === null) {
  //   localStorageService.clearLocalStorageItemValue('accessCode')
  // }

  // contentService.getPosterFile(eventCode).then((res) => {
  //   const image = URL.createObjectURL(res.data)
  //   setPosterImage(image)
  // })

  return <div>NewOAuth2Page</div>
}
